import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  search(startRange, endRange, params) {
    return http.get(`${resources.SEARCH}/${startRange};${endRange}`, {
      params
    });
  },

  getServiceById(serviceId) {
    return http.get(`${resources.SERVICES}/${serviceId}`);
  },

  getAnnouncements(params) {
    return http.get(`${resources.ANNOUNCEMENT}/`, {
      params
    });
  }
};
