import React from 'react';
import classNames from 'classnames';

import './styles.css';

const AppAvatarComponent = ({ src, size, alt, round }) => {
  const imgClassess = classNames('img-fluid', {
    app_avatar__small: !!round,
    app_avatar__round: size === 'small',
    app_avatar__medium: size === 'medium',
    app_avatar__large: size === 'large'
  });
  return <img alt={alt} src={src} className={imgClassess} />;
};

export default AppAvatarComponent;
