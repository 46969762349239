import { combineReducers } from 'redux';

import uiReducer from './ui.reducer';
import locationReducer from './locations.reducer';
import categoryReducer from './categories.reducer';

export default combineReducers({
  ui: uiReducer,
  locations: locationReducer,
  categories: categoryReducer
});
