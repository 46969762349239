import JobsListContainer from './list/JobsList';
import JobDetailsContainer from './details/JobDetails';

export default [
  {
    path: '/jobs',
    exact: true,
    component: JobsListContainer
  },
  {
    exact: false,
    path: '/jobs/:jobId',
    component: JobDetailsContainer
  }
];
