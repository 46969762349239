import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import React from 'react';

import routes from './forgot-password-routes.js';

class ForgotPasswordContainer extends React.Component {
  render() {
    return (
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route key={index} path={route.path} component={route.component} />
          );
        })}
      </Switch>
    );
  }
}

export default compose(withTranslation())(ForgotPasswordContainer);
