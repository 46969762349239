import React from 'react';
import { Col, Row } from 'reactstrap';

import styles from './SimpleCard.module.css';

export const SimpleCard = ({ text, icon, clickHandler, iconRight }) => {
  if (!text) {
    return null;
  }

  const imagePosition = iconRight ? styles.alignRight : null;

  return (
    <Row onClick={() => clickHandler(text)} title={text}>
      <Col xs={12} className={styles.simple_card}>
        {icon && <img src={icon} className={imagePosition} alt={text} />}
        <p className="text-truncate">{text}</p>
      </Col>
    </Row>
  );
};
