import React from 'react';
import { Container } from 'reactstrap';

import routes from './stores-routes';
import SessionRoute from '../../base/SessionRoute';

export class StoresContainer extends React.Component {
  render() {
    return (
      <Container>
        {routes.map((route, index) => {
          return (
            <SessionRoute
              key={index}
              exact={route.exact}
              path={route.path}
              session={this.props.session}
              component={route.component}
            />
          );
        })}
      </Container>
    );
  }
}

export default StoresContainer;
