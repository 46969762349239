import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const SessionRoute = ({
  component: Component,
  session,
  redirectTo,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        redirectTo ? (
          <Redirect to={redirectTo} />
        ) : (
          <Component session={session} {...props} />
        )
      }
    />
  );
};

export default SessionRoute;
