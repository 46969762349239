import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { setSearch } from '../store/actions';
import styles from './LocationsList.module.scss';
import { findLocationsBy } from '../../common/utils';
import { NoResults, LocationResultCard } from '../../common/components';

class LocationsList extends React.Component {
  locationClick = locationId => {
    const { match } = this.props;
    const categoryId = +match.params.categoryId;

    this.props.history.push(`/maps/${categoryId}/${locationId}`);
  };

  componentDidMount() {
    const { match, categories, query } = this.props;
    const categoryId = +match.params.categoryId;
    const category = categories.find(c => c.id === categoryId);

    if (!query && categoryId === -1) {
      this.props.history.push('/maps');
    }

    if (!query && category) {
      this.props.setSearch(category.name);
    }
  }

  render() {
    const locations = this.props.locations;
    const { t, match, query } = this.props;
    const categoryId = +match.params.categoryId;

    const getLocationByCategoryId = locations.filter(
      l => l.category_id === categoryId
    );
    const listItems =
      categoryId === -1
        ? findLocationsBy(locations, query)
        : getLocationByCategoryId;

    return (
      <React.Fragment>
        <ul className={`no_scroll_bar ${styles.locations__list}`}>
          <Row noGutters>
            {listItems.length ? (
              listItems.map(location => (
                <Col xs={12} key={location.id}>
                  <li>
                    <LocationResultCard
                      {...location}
                      clickHandler={this.locationClick}
                    />
                  </li>
                </Col>
              ))
            ) : (
              <NoResults message={t('t_shared_no_locations_found')} />
            )}
          </Row>
        </ul>
      </React.Fragment>
    );
  }
}

function mapStateToProps({
  session,
  locations: { locations, categories, ui }
}) {
  return {
    session,
    query: ui.query,
    locations: locations.locations,
    categories: categories.categories
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearch: payload => dispatch(setSearch(payload))
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LocationsList);
