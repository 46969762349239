import { useTranslation, Translation } from 'react-i18next';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import styles from './HoursCard.module.scss';
import { timeFromSeconds } from '../../../../../utils/dates';
import {
  today,
  dayMapping,
  addMissingDays,
  filterItemsBasedOnCurrentTime
} from '../../utils';

const hasSchedule = schedule => Boolean(schedule.length);
const isTodaySchedule = schedule => schedule.day === today;

export const openningTimes = items => {
  if (!items) {
    return <Translation>{t => t('t_shared_closed')}</Translation>;
  }
  const endTime = timeFromSeconds(items.end_time);
  const startTime = timeFromSeconds(items.start_time);

  return `${startTime} - ${endTime}`;
};

const cardHeader = (expanded, clickHandler) => {
  const { t } = useTranslation();
  return (
    <Row className={styles.heading}>
      <Col>
        <strong>{t('t_shared_hours')}</strong>
      </Col>
      <Col className="text-right">
        <button type="button" className="button-reset" onClick={clickHandler}>
          {expanded ? t('t_shared_see_less') : t('t_shared_see_more')}
        </button>
      </Col>
    </Row>
  );
};

export const HoursCardLocation = ({ t, schedule }) => {
  const [expanded, toggle] = useState(false);

  if (!hasSchedule(schedule)) {
    return null;
  }

  schedule = addMissingDays(schedule);

  if (!expanded) {
    schedule = schedule.filter(isTodaySchedule);
  }

  return (
    <div className={styles.hours_card}>
      {cardHeader(expanded, () => toggle(!expanded))}
      <ul className={styles.schedules}>
        {schedule.map((s, index) => (
          <li key={index}>
            <Row>
              <Col>{t(dayMapping[s.day])}</Col>
              <Col className="text-right">{openningTimes(s.items[0])}</Col>
            </Row>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const HoursCardDining = ({ t, schedule }) => {
  if (!hasSchedule(schedule)) {
    return null;
  }

  const [expanded, toggle] = useState(false);
  schedule = addMissingDays(schedule);

  if (!expanded) {
    schedule = schedule.find(isTodaySchedule);

    schedule = {
      ...schedule,
      items: schedule.items.length
        ? [filterItemsBasedOnCurrentTime(schedule.items)]
        : []
    };
    schedule = [schedule];
  }

  return (
    <div className={styles.hours_card}>
      {cardHeader(expanded, () => toggle(!expanded))}
      <ul className={styles.schedules}>
        {schedule.map((s, index) => (
          <li className={styles.dining_list_item} key={`schedule_${index}`}>
            <Row>
              <Col>
                <strong>{t(dayMapping[s.day])}</strong>
              </Col>
              {!s.items.length && (
                <Col className="text-right">{t('t_shared_closed')}</Col>
              )}
            </Row>
            {s.items.map((i, index) => (
              <Row key={`items_${index}`}>
                <Col>{i.name}</Col>
                <Col className="text-right">{openningTimes(i)}</Col>
              </Row>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};
