export const tileContentTypeToRoute = (url, id) => {
  switch (url) {
    case 'event_list':
      return '/events';

    case 'store_list':
      return '/stores';

    case 'campus_poi_list/dining':
      return '/dining';

    case 'campus_poi_list':
      return '/maps';

    case 'school_campaign':
      return `/campaigns/${id}`;

    case 'campaign_list':
      return '/campaigns';

    case 'job_list':
      return 'jobs';

    case 'exam_search':
      return '/exams';

    case 'store':
      return `/stores/${id}?redirect=true`;

    case 'deal_store_list':
      return '/deals';

    case 'campus_link_list':
      return '/links';

    case 'campus_service':
      return `/services/${id}`;

    case 'campus_service_list':
      return '/services';

    case 'noop':
    default:
      return null;
  }
};
