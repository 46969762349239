import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './styles.css';
import { Col } from 'reactstrap';
import service from '../services-service';
import Pagination from '../../../base/Pagination';
import AppList from '../../../components/app-list/AppList';
import AppSearchBarComponent from '../../../components/app-search/AppSearch';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppNoResultsComponent from '../../../components/app-no-results/AppNoResults';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import {
  categoriesToFilter,
  serviceToListComponent,
  paramsToAction
} from '../services-utils';
import AppPagination from '../../../components/app-pagination/AppPagination';

class ServicesListContainer extends React.Component {
  pagination = new Pagination();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      services: [],
      categories: [],
      search_str: null,
      filterByCategoryId: null
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  goBack() {
    this.pagination.goBack();
    this.fetch({ mutateState: true });
  }

  goNext() {
    this.pagination.goNext();
    this.fetch({ mutateState: true });
  }

  componentDidMount() {
    this.props.dispatch(
      updateBreadCrumb([
        {
          url: `services${this.props.location.search}`,
          label: this.props.t('services')
        }
      ])
    );
    this.setState({ loading: true });
    this.fetch();
  }

  handleSearch(search_str) {
    this.setState({ search_str }, () => {
      this.fetch({ mutateState: true });
    });
  }

  handleFilter({ id }) {
    this.setState({ filterByCategoryId: id }, () =>
      this.fetch({ mutateState: true })
    );
  }

  servicesSearchParams(filterBy) {
    const search = new URLSearchParams();
    search.append('search_str', this.state.search_str);
    search.append('school_id', this.props.session.school.id);

    if (filterBy && filterBy.type === 'includes') {
      search.append('category_ids', filterBy.ids);
    }

    if (filterBy && filterBy.type === 'excludes') {
      search.append('x_category_ids', filterBy.ids);
    }

    if (this.state.filterByCategoryId) {
      if (search.get('x_category_ids')) {
        search.delete('x_category_ids');
      }
      search.set('category_ids', this.state.filterByCategoryId);
    }

    return search;
  }

  categoriesSearchParams() {
    const search = new URLSearchParams();
    search.append('category_only', 1);
    search.append('school_id', this.props.session.school.id);

    return search;
  }

  fetch(mutateState = false) {
    this.setState({ loading: true }, loading => {
      const queryParams = this.props.location.search;
      const filterBy = queryParams
        ? paramsToAction(this.props.location.search)
        : null;

      const search = this.servicesSearchParams(filterBy);
      const categoriesSearch = this.categoriesSearchParams();

      // load categories only during initial page load
      const request = !this.state.categories.length
        ? Promise.all([
            service.getServices(
              this.pagination.startRange,
              this.pagination.endRange,
              search
            ),
            service.getServiceCategories(
              categoriesSearch,
              filterBy ? filterBy.type : null,
              filterBy ? filterBy.ids : null
            )
          ])
        : Promise.all([
            service.getServices(
              this.pagination.startRange,
              this.pagination.endRange,
              search
            )
          ]);

      request.then(([services, categories]) => {
        this.setState({
          loading: false,
          categories: categories
            ? categoriesToFilter(categories)
            : this.state.categories,
          services: mutateState
            ? serviceToListComponent(services)
            : this.state.services.concat(serviceToListComponent(services))
        });
      });
    });
  }

  render() {
    const { t } = this.props;

    const filters = this.state.categories.map(({ id, label }) => {
      return {
        id,
        label
      };
    });

    const searchBar = (
      <AppSearchBarComponent
        filters={filters.length > 1 ? filters : null}
        placeholder={t('services_search_placeholder')}
        onSearch={this.handleSearch}
        onFiltered={this.handleFilter}
      />
    );

    const servicesList = <AppList items={this.state.services} />;

    return (
      <div className="services_page page-padding-top">
        <p className="h1 strong text-center">{t('services')}</p>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          <div className="search">{searchBar}</div>
          {this.state.loading ? (
            <AppSpinnerComponent centered />
          ) : (
            <div>
              {this.state.services.length ? (
                <div>
                  {servicesList}
                  <AppPagination
                    goBack={this.goBack}
                    goNext={this.goNext}
                    page={this.pagination.currentPage}
                    next={
                      this.pagination.maxPerPage === this.state.services.length
                    }
                  />
                </div>
              ) : (
                <div className="no_results">
                  <AppNoResultsComponent query={this.state.search_str} />
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(ServicesListContainer);
