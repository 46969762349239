import { fromEpoch } from '../../utils/dates';

export const jobToListComponent = jobs => {
  return jobs.map(job => {
    return {
      id: job.id,
      heading: job.title,
      image: job.store_logo_url,
      description: job.description,
      linkTo: `/jobs/${job.id}`
    };
  });
};

const yearsMap = {
  U1: 'jobs_desired_study_ug_y1',
  U2: 'jobs_desired_study_ug_y2',
  U3: 'jobs_desired_study_ug_y3',
  U4: 'jobs_desired_study_ug_y4',
  MS: 'jobs_desired_study_masters',
  PHD: 'jobs_desired_study_phd'
};

export const yearsOfStudy = years => years.map(year => yearsMap[year]);

const jobTypesArray = [
  { key: 'is_credited', display: 'jobs_job_type_for_credit' },
  { key: 'is_full_time', display: 'jobs_job_type_full_time' },
  { key: 'is_internship', display: 'jobs_job_type_internship' },
  { key: 'is_oncampus', display: 'jobs_job_type_on_campus' },
  { key: 'is_part_time', display: 'jobs_job_type_part_time' },
  { key: 'is_summer', display: 'jobs_job_type_summer' },
  { key: 'is_volunteer', display: 'jobs_job_type_volunteer' }
];

export const jobTypes = job => {
  let displays = [];
  jobTypesArray.forEach(type => {
    if (job[type.key]) {
      displays = [...displays, type.display];
    }
  });
  return displays;
};

export const detailsToSidebar = job => {
  return {
    hero: {
      src: job.store_logo_url,
      alt: job.store_name
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_employer',
        value: job.store_name
      },
      {
        id: 2,
        title: 'sidebar_application_deadline',
        value: fromEpoch(job.application_deadline, false)
      },
      {
        id: 3,
        title: 'sidebar_job_start_date',
        value: fromEpoch(job.contract_start, false)
      },
      {
        id: 4,
        title: 'sidebar_location',
        value: job.location
      },
      {
        id: 5,
        title: 'sidebar_email',
        value: job.store_email
      },
      {
        id: 6,
        title: 'sidebar_website',
        value: job.store_website
      },
      {
        id: 7,
        title: 'sidebar_phone',
        value: job.store_phone
      }
    ]
  };
};
