import { production } from '../environment';

const logger = {
  log(message) {
    if (!production) {
      console.info(message);
    }
  }
};

export default logger;
