/* global google */
import React from 'react';
import ReactDOM from 'react-dom';

import './AppMap.css';
import { Popup } from './Popup';
import { toBase64 } from '../../utils/svg';

const createSmallMarkerIcon = fill => {
  return `
  <svg width="12" height="23" xmlns="http://www.w3.org/2000/svg">
    <g>
    <rect fill="none" id="canvas_background" height="25" width="14" y="-1" x="-1"/>
    <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
      <rect fill="url(#gridpattern)" stroke-width="0" y="0" x="0" height="100%" width="100%"/>
    </g>
    </g>
    <g>
    <ellipse ry="3.740598" rx="3.740598" id="svg_1" cy="5.361978" cx="6.076751" stroke-width="3" stroke="${fill}" fill="none"/>
    <ellipse ry="2.283593" rx="2.283593" id="svg_2" cy="14.211984" cx="6.076751" stroke-width="1.5" fill="${fill}"/>
    <ellipse ry="2.283593" rx="2.283593" id="svg_8" cy="20.649476" cx="6.076751" stroke-width="1.5" fill="${fill}"/>
    </g>
  </svg>
  `;
};
class AppMapComponent extends React.Component {
  map;
  markers = [];

  infoViewRef = React.createRef();

  loadMap() {
    const mapRef = this.refs.map;
    const node = ReactDOM.findDOMNode(mapRef);

    return new google.maps.Map(node, {
      center: this.props.center,
      zoom: this.props.zoom,
      scrollwheel: false,
      disableDefaultUI: false,
      fullscreenControl: false,
      keyboardShortcuts: false
    });
  }

  fitMapToBounds = () => {
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.markers.length; i++) {
      bounds.extend(this.markers[i].getPosition());
    }

    this.map.fitBounds(bounds);
  };

  loadMarkers() {
    if (!this.map) {
      return;
    }

    this.props.markers.forEach(marker => {
      const pref = {
        icon: marker.icon,
        map: this.map,
        title: marker.title,
        address: marker.address,
        position: new google.maps.LatLng(marker.latitude, marker.longitude)
      };

      const markerEl = new google.maps.Marker(pref);
      markerEl['visibile'] = false;
      const popup = new Popup(
        markerEl.position,
        this.infoViewRef.current,
        marker.icon,
        marker.title
      );

      this.markers.push(markerEl);

      markerEl.addListener('click', () => {
        markerEl['visibile'] = !markerEl['visibile'];
        if (markerEl['visibile']) {
          markerEl.setIcon({
            url: toBase64(createSmallMarkerIcon(marker.color))
          });
          popup.setMap(this.map);
        } else {
          popup.setMap(null);
          markerEl.setIcon(marker.icon);
        }
      });
    });

    if (this.markers.length === 1) {
      const marker = this.markers[0];
      google.maps.event.trigger(marker, 'click');
    }
  }

  componentDidMount() {
    this.map = this.loadMap();

    if (this.props.markers) {
      this.loadMarkers();
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.centerToMarker) {
      const marker = this.markers.filter(
        marker => marker.address === nextProps.centerToMarker.address
      )[0];

      this.map.panTo(marker.getPosition());
      google.maps.event.trigger(marker, 'click');
    }
  }

  render() {
    const map = {
      height: '100%',
      width: '100%'
    };

    return (
      <React.Fragment>
        <div style={map} className="map" ref="map" />
        <div ref={this.infoViewRef} />
      </React.Fragment>
    );
  }
}

export default AppMapComponent;
