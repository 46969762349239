import React from 'react';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Collapse, Navbar, NavbarToggler } from 'reactstrap';

import './styles.css';

import service from './navbar-service';
import { search, reset } from './app-navbar-actions';
import { objectTypeToRoute } from './app-navbar-utils';
import { gaTrackPage } from '../../services/analytics.service';
import AppNavLinks from './components/app-nav-links/AppNavLinks';
import AppNavBrand from './components/app-nav-brand/AppNavBrand';
import AppNavSearch from './components/app-nav-search/AppNavSearch';
import AppNavSearchResults from './components/search-results/AppNavSearchResults';

class AppNavBarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search_str: null,
      suggestions: [],
      searching: false,
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
  }

  toggle() {
    this.setState({
      search_str: null,
      suggestions: [],
      searching: false,
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    this.props.history.listen(() => {
      gaTrackPage(this.props.history.location.pathname);
    });
  }

  clearSearch() {
    this.setState({
      search_str: null,
      suggestions: [],
      searching: false,
      isOpen: false
    });
  }

  doSearch() {
    if (!this.state.search_str) {
      this.clearSearch();
      return;
    }

    const search = new URLSearchParams();
    search.append('school_id', this.props.school.id);
    search.append('search_str', this.state.search_str);
    search.append('obj_types', 'campus_service,event,store');

    service.search(1, 100, search).then(suggestions => {
      this.setState({
        suggestions: suggestions.map(item => {
          return {
            id: item.id,
            heading: item.name,
            image: item.img_url,
            description: item.description,
            linkTo: `/${objectTypeToRoute(item.obj_type)}/${item.id}`
          };
        }),
        searching: false
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.dispatch(reset());
    }
  }

  handleQuery(search_str) {
    this.props.dispatch(search({ searching: !!search_str, query: search_str }));
    this.setState({ search_str, searching: true }, () => this.doSearch());
  }

  render() {
    const { school } = this.props;

    return (
      <div className="app__navbar">
        <Container>
          <Navbar color="faded" light expand="md">
            <AppNavBrand logo={school.app_logo_url} />

            <NavbarToggler onClick={this.toggle} />

            <Collapse isOpen={this.state.isOpen} navbar>
              <div className="app__navbar__search mr-auto">
                <AppNavSearch
                  schoolName={school.name}
                  handleQuery={debounce(this.handleQuery, 400)}
                />
              </div>
              <AppNavLinks
                {...this.props}
                toggleDownloadModal={this.props.toggleDownloadModal}
              />
            </Collapse>
          </Navbar>
        </Container>

        {this.props.searching ? (
          <AppNavSearchResults
            query={this.state.search_str}
            searching={this.state.searching}
            suggestions={this.state.suggestions}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ masterSearch }) => {
  return {
    searching: masterSearch.searching
  };
};

export default compose(withRouter, connect(mapStateToProps))(
  AppNavBarComponent
);
