import { locationsActions } from '../actions';

const initialState = {
  loading: true,
  query: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case locationsActions.LOADING_START:
      return {
        ...state,
        loading: true
      };

    case locationsActions.LOADING_END:
      return {
        ...state,
        loading: false
      };

    case locationsActions.SET_SEARCH_VALUE:
      const query = action.payload;

      return {
        ...state,
        query
      };

    default:
      return state;
  }
};
