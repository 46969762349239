export const MASTER_SEARCH_SEARCH = 'MASTER_SEARCH_SEARCH';
export const MASTER_SEARCH_RESET = 'MASTER_SEARCH_RESET';

export const search = payload => ({
  type: MASTER_SEARCH_SEARCH,
  payload
});

export const reset = payload => ({
  type: MASTER_SEARCH_RESET,
  payload: {
    searching: false,
    query: ''
  }
});
