import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styles from './AppSnackbar.module.css';
import { hideSnackbar } from '../../store/core/ui/snackbar/actions';

class AppSnackbarComponent extends React.Component {
  render() {
    if (this.props.visible) {
      setTimeout(() => {
        this.props.hideSnackbar();
      }, this.props.timeOut);
    }
    const containerStyles = classNames(
      styles.app_snackbar,
      this.props.visible ? styles.show : ''
    );
    const wrapperStyles = classNames(styles.wrapper, styles[this.props.type]);
    return (
      <div className={containerStyles}>
        <div className={wrapperStyles}>{this.props.message}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ ui: { snackBar } }) => {
  return {
    type: snackBar.type,
    visible: snackBar.show,
    timeOut: snackBar.timeOut,
    message: snackBar.message
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ hideSnackbar, dispatch }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSnackbarComponent);
