import React from 'react';
import { Col, Row } from 'reactstrap';

import styles from './BackButton.module.scss';
import backIcon from './ic_arrow_left_outline.png';

export const BackButton = ({ children, clickHandler }) => {
  return (
    <Row>
      <Col xs={12} className={styles.back_button}>
        <button
          onClick={clickHandler}
          className={`button-reset ${styles.some}`}
        >
          <img src={backIcon} alt="back" />
          <strong>{children}</strong>
        </button>
      </Col>
    </Row>
  );
};
