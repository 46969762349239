import React from 'react';

import styles from './CategoryResultCard.module.scss';

export const CategoryResultCard = ({
  name,
  img_url,
  color,
  id,
  categoryClick
}) => {
  return (
    <button
      title={name}
      onClick={() => categoryClick(id)}
      className={`button-reset ${styles.button}`}
    >
      <div className={styles.wrapper} style={{ backgroundColor: `#${color}` }}>
        <img alt={name} src={img_url} className={styles.image} />
      </div>
      <span className={`text-truncate ${styles.label}`}>{name}</span>
    </button>
  );
};
