import StoresListContainer from './list/StoresList';
import StoreDetailsContainer from './details/StoreDetails';

export default [
  {
    path: '/stores',
    exact: true,
    component: StoresListContainer
  },
  {
    exact: false,
    path: '/stores/:storeId',
    component: StoreDetailsContainer
  }
];
