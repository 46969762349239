import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Card as BaseCard, CardBody } from 'reactstrap';

import './styles.css';

const tileBorder = color => `2px solid ${color}`;

const Card = styled(BaseCard)`
  border-radius: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: ${props => tileBorder(props.border)};
`;

const LinkEl = props => {
  const { linkTo, external } = props;
  return external ? (
    <a
      href={linkTo}
      className="link link--text"
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  ) : (
    <Link className="link link--text" to={linkTo}>
      {props.children}
    </Link>
  );
};

const AppTileComponent = ({
  id,
  type,
  color,
  img_url,
  name,
  linkTo,
  external
}) => {
  const card = (
    <Card border={`#${color}`}>
      <img className="tile__hero" src={img_url} alt={name} />
      <CardBody className="tile__body">
        <div className="tile__title_container">
          <p className="tile__title">{name}</p>
        </div>
      </CardBody>
    </Card>
  );
  return linkTo ? (
    <LinkEl linkTo={linkTo} external={external}>
      {card}
    </LinkEl>
  ) : (
    card
  );
};

export default AppTileComponent;
