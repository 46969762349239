import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './styles.css';
import { Col } from 'reactstrap';
import service from '../deals-service';
import Pagination from '../../../base/Pagination';
import { dealToListComponent } from '../deals-utils';
import AppList from '../../../components/app-list/AppList';
import AppSearchBarComponent from '../../../components/app-search/AppSearch';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppNoResultsComponent from '../../../components/app-no-results/AppNoResults';
import AppPagination from '../../../components/app-pagination/AppPagination';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';

class DealsListContainer extends React.Component {
  pagination = new Pagination();

  constructor(props) {
    super(props);

    this.state = {
      deals: [],
      loading: false,
      search_str: null
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  goBack() {
    this.pagination.goBack();
    this.fetch();
  }

  goNext() {
    this.pagination.goNext();
    this.fetch();
  }

  handleSearch(search_str) {
    this.setState({ search_str }, () => this.fetch({ mutateState: true }));
  }

  componentDidMount() {
    this.props.dispatch(
      updateBreadCrumb([
        {
          url: `deals`,
          label: this.props.t('deals')
        }
      ])
    );
    this.fetch();
  }

  fetch(mutateState = false) {
    const schoolId = this.props.session.school.id;

    this.setState({ loading: true }, () => {
      const { startRange, endRange } = this.pagination;
      const search = new URLSearchParams();
      search.append('school_id', schoolId);
      search.append('search_str', this.state.search_str);

      service.getDeals(startRange, endRange, search).then(deals => {
        this.setState({
          loading: false,
          deals: mutateState
            ? dealToListComponent(deals)
            : this.state.deals.concat(dealToListComponent(deals))
        });
      });
    });
  }

  render() {
    const { t } = this.props;

    const searchBar = (
      <AppSearchBarComponent
        placeholder={t('deals_search_placeholder')}
        onSearch={this.handleSearch}
      />
    );

    const dealsList = <AppList items={this.state.deals} />;
    return (
      <div className="services_page page-padding-top">
        <p className="h1 strong text-center">{t('deals')}</p>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          <div className="search">{searchBar}</div>
          {this.state.loading ? (
            <AppSpinnerComponent centered />
          ) : (
            <div>
              {this.state.deals.length ? (
                <div>
                  {dealsList}
                  <AppPagination
                    goBack={this.goBack}
                    goNext={this.goNext}
                    page={this.pagination.currentPage}
                    next={
                      this.pagination.maxPerPage === this.state.deals.length
                    }
                  />
                </div>
              ) : (
                <div className="no_results">
                  <AppNoResultsComponent query={this.state.search_str} />
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(DealsListContainer);
