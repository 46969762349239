import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';

import logger from './services/logger.service';
import SessionRoute from './base/SessionRoute';
import service from './services/school.service';
import JobsContainer from './containers/jobs/Jobs';
import { production, staging } from './environment';
import DealsContainer from './containers/deals/Deals';
import ClubsContainer from './containers/clubs/Clubs';
import LinksContainer from './containers/links/Links';
import storage, { STORAGE_KEYS } from './utils/storage';
import EventsContainer from './containers/events/Events';
import StoresContainer from './containers/stores/Stores';
import { sessionInit } from './store/core/session/actions';
import ServicesContainer from './containers/services/Services';
import AthleticsContainer from './containers/athletics/Athletics';
import CampaignsContainer from './containers/campaigns/Campaigns';
import AppNavBarComponent from './components/app-navbar/AppNavBar';
import CampusMapContainer from './containers/campus-map/CampusMap';
import AppInfoModal from './components/app-info-modal/AppInfoModal';
import AppSpinnerComponent from './components/app-spinner/AppSpinner';
import CampusGuideContainer from './containers/campus-guide/CampusGuide';
import AppSnackbarComponent from './components/app-snackbar/AppSnackbar';
import AppBreadcrumbComponent from './components/app-breadcrumb/AppBreadcrumb';
import AppDownloadModal from './components/app-download-modal/AppDownloadModal';
import campusGuideService from './containers/campus-guide/campus-guide-service';

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      school: null,
      loading: true,
      personas: null,
      appConfig: null,
      securityTile: null,
      downloadAppModal: false
    };

    this.onToggleDownloadModal = this.onToggleDownloadModal.bind(this);
  }

  getSchool() {
    const search = new URLSearchParams();
    search.append('webapp_url', window.location);

    return production || staging
      ? service.getSchoolByDomain(search)
      : service.getSchoolById(157);
  }

  getPersonas(schoolId) {
    const search = new URLSearchParams();
    search.append('school_id', schoolId);
    search.append('platform', 1);

    return service.getPersonas(1, 100, search);
  }

  redirectToLandingPage() {
    window.location.replace(
      this.state.school.landing_page || 'https://readyeducation.com'
    );
  }

  bootApp() {
    return this.getSchool()
      .then(school => {
        this.setState({ school });
        return this.getPersonas(school.id);
      })
      .then(personas => {
        if (!personas.length) {
          this.redirectToLandingPage();
          return;
        }
        this.setState({ personas });

        const search = new URLSearchParams();
        search.append('school_id', this.state.school.id);
        const storedPersona = storage.get(STORAGE_KEYS.defaultPersona);
        const defaultPersona = storedPersona ? storedPersona : personas[0].id;

        search.append('school_persona_id', defaultPersona);

        return campusGuideService.getTilesByPersonaId(search).catch(() => {
          return Promise.reject('INVALID_PERSONA');
        });
      })
      .then(response => {
        const { securityTile } = response;
        if (
          !securityTile ||
          !securityTile.extra_info ||
          !securityTile.extra_info.link_params ||
          typeof securityTile.extra_info.link_params.id !== 'number'
        ) {
          return response;
        }

        /**
         * do not render security tile widget if
         * service is deleted
         */
        return campusGuideService
          .getServiceById(securityTile.extra_info.link_params.id)
          .then(() => response)
          .catch(() => {
            const { securityTile, ...rest } = response;
            return rest;
          });
      })
      .then(response => {
        const {
          securityTile,
          defaultTiles,
          featuredTiles,
          enforced_locale,
          personalized_guide
        } = response;

        if (enforced_locale === 'fr-FR' && i18n.language !== 'fr') {
          i18n.changeLanguage('fr');
        }

        this.props.dispatch(
          sessionInit({
            school: this.state.school,
            featuredTiles: featuredTiles
          })
        );

        this.setState({
          loading: false,
          securityTile: securityTile,
          appConfig: {
            defaultTiles: defaultTiles,
            featuredTiles: featuredTiles,
            personalized_guide: personalized_guide
          }
        });
      })
      .catch(err => {
        if (err === 'INVALID_PERSONA') {
          storage.remove(STORAGE_KEYS.defaultPersona);
          this.bootApp();
          return;
        }

        this.setState({ loading: false, error: true });
      });
  }

  componentDidMount() {
    this.bootApp();
  }

  onToggleDownloadModal() {
    this.setState({ downloadAppModal: !this.state.downloadAppModal });
  }
  render() {
    if (!this.state.loading && !production) {
      logger.log(`APP STATE ${this.state}`);
    }
    const school = this.state.loading ? null : this.state.school;

    return (
      <React.Fragment>
        {this.state.loading ? (
          <AppSpinnerComponent centered />
        ) : (
          <div className="app">
            {this.state.error ? (
              <AppInfoModal>Something went wrong</AppInfoModal>
            ) : (
              <React.Fragment>
                <AppSnackbarComponent />
                <AppNavBarComponent
                  school={school}
                  securityTile={this.state.securityTile}
                  toggleDownloadModal={this.onToggleDownloadModal}
                />
                <div className="app__content">
                  <AppBreadcrumbComponent />
                  <SessionRoute
                    exact
                    path="/"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={CampusGuideContainer}
                  />
                  <SessionRoute
                    path="/clubs"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={ClubsContainer}
                  />
                  <SessionRoute
                    path="/athletics"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={AthleticsContainer}
                  />
                  <SessionRoute
                    path="/campaigns"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={CampaignsContainer}
                  />
                  <SessionRoute
                    path="/stores"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={StoresContainer}
                  />
                  <SessionRoute
                    path="/links"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={LinksContainer}
                  />
                  <SessionRoute
                    path="/services"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={ServicesContainer}
                  />
                  <SessionRoute
                    path="/jobs"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={JobsContainer}
                  />
                  <SessionRoute
                    path="/events"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={EventsContainer}
                  />
                  <SessionRoute
                    path="/deals"
                    session={{
                      school: this.state.school,
                      personas: this.state.personas,
                      appConfig: this.state.appConfig
                    }}
                    component={DealsContainer}
                  />
                  <Route path="/maps" component={CampusMapContainer} />
                  <Route path="/dining" component={CampusMapContainer} />
                </div>
                <AppDownloadModal
                  t={this.props.t}
                  toggle={this.onToggleDownloadModal}
                  isOpen={this.state.downloadAppModal}
                  iosUrl={this.state.school.app_url_ios}
                  androidUrl={this.state.school.app_url_android}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect()(AppContainer);
