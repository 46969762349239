import DealsListContainer from './list/DealsList';
import DealsStoreContainer from './store/DealsStore';
import DealDetailsContainer from './details/DealDetails';

export default [
  {
    path: '/deals',
    exact: true,
    component: DealsListContainer
  },
  {
    exact: true,
    path: '/deals/:dealId',
    component: DealDetailsContainer
  },
  {
    exact: true,
    path: '/deals/:dealId/store',
    component: DealsStoreContainer
  }
];
