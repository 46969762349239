import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../athletics-service';
import { fromEpoch } from '../../../utils/dates';
import Pagination from '../../../base/Pagination';
import { detailsToSidebar } from '../athletics-utils';
import AppList from '../../../components/app-list/AppList';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppSidebarComponent from '../../../components/app-sidebar/AppSidebar';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

class AthleticDetailsContainer extends React.Component {
  clubId;
  pagination = new Pagination();

  constructor(props) {
    super(props);
    this.clubId = this.props.match.params.clubId;
    this.state = {
      loading: true,
      club: null,
      events: []
    };
  }

  componentDidMount() {
    const { startRange, endRange } = this.pagination;
    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service
      .getClubById(this.clubId, search)
      .then(response => {
        this.setState({ club: response });

        const search = new URLSearchParams();
        search.append('category_id', -1);
        search.append('store_id', response.id);
        search.append('school_id', this.props.session.school.id);

        return service.getEventsByClubId(startRange, endRange, search);
      })
      .then(events => {
        this.setState(
          {
            loading: false,
            events: events.map(event => {
              return {
                id: event.id,
                heading: event.title,
                linkTo: `/events/${event.id}`,
                description: event.description,
                subheading: fromEpoch(event.start),
                image: event.poster_thumb_url
              };
            })
          },
          () => {
            this.props.dispatch(
              updateBreadCrumb([
                {
                  url: `athletics/${this.state.club.id}`,
                  label: this.state.club.name
                }
              ])
            );
          }
        );
      });
  }

  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="page-padding-top">
        <p className="h1 strong">{this.state.club.name}</p>
        <Row>
          <Col xs={12} md={8} className="description">
            <article>
              <AppParagraph text={this.state.club.description} />
              <div className="service_details_list">
                <AppList items={this.state.events} />
              </div>
            </article>
          </Col>
          <Col xs={12} md={{ size: 3, offset: 1 }} className="meta">
            <AppSidebarComponent {...detailsToSidebar(this.state.club)} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(AthleticDetailsContainer);
