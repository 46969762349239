import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getLocations(startRange, endRange, params) {
    return http.get(
      `${resources.MAPS}/${startRange};${endRange}`,
      {
        params
      },
      false
    );
  },

  getLocationCategories(params) {
    return http.get(
      `${resources.MAPS_CATEGORIES}/`,
      {
        params
      },
      false
    );
  }
};
