import React from 'react';
import { Media } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.css';
import AppMapComponent from '../app-map/AppMap';

export const appSidebarTypes = {
  location: 'location',
  email: 'email',
  phone: 'phone',
  url: 'url'
};

export const AppSidebarComponent = props => {
  const { t } = props;

  return (
    <aside className="app_aside">
      {props.hero.src ? (
        <div
          className="app_aside__hero"
          style={{ backgroundImage: 'url(' + props.hero.src + ')' }}
        />
      ) : null}
      {props.map ? (
        <div className="app_aside__map">
          <AppMapComponent
            zoom={15}
            center={{
              lat: props.map.latitude,
              lng: props.map.longitude
            }}
          />
        </div>
      ) : null}
      {props.list ? (
        <div className="app_side__list">
          <p className="h4 strong">{t(props.list.heading)}</p>
          <ul>
            {props.list.data.length ? (
              props.list.data.map(item => {
                return (
                  <li key={item.id}>
                    <Media>
                      <Media left>
                        <img src={item.avatar} alt={item.label} />
                      </Media>
                      <Media body>
                        <p>{item.label}</p>
                        <small className="text-muted">{item.subheading}</small>
                      </Media>
                    </Media>
                  </li>
                );
              })
            ) : (
              <li>{t('clubs_sidebar_no_executives')}</li>
            )}
          </ul>
        </div>
      ) : null}
      <div className="app_aside__meta">
        {props.meta.length
          ? props.meta.map(item => {
              return item.value ? (
                <div key={item.id} className="app_aside__meta__item">
                  <p className="h4 strong">{t(item.title)}</p>
                  {item.type === appSidebarTypes.url ? (
                    <a
                      target="_blank"
                      href={item.value}
                      rel="noopener noreferrer"
                      className="app_aside__meta__value"
                    >
                      {item.value}
                    </a>
                  ) : (
                    <p className="app_aside__meta__value">{item.value}</p>
                  )}
                </div>
              ) : null;
            })
          : null}
      </div>
    </aside>
  );
};

export default withTranslation()(AppSidebarComponent);
