import http from '../../services/http.service';
import resources from '../../config/api/resources';
import { clubMemberType } from '../clubs/clubs-utils';

export default {
  getStores(startRange, endRange, params) {
    return http.get(`${resources.STORE}/${startRange};${endRange}`, {
      params
    });
  },

  getClubMembers(params) {
    return http.get(`${resources.USER}/1;100`, { params }).then(members => {
      return Promise.resolve(
        members.filter(
          member => member.member_type === clubMemberType.executive
        )
      );
    });
  },

  getStoreById(storeId, params) {
    return http.get(`${resources.STORE}/${storeId}`, {
      params
    });
  },

  getEventsByStoreId(startRange, endRange, params) {
    return http.get(`${resources.EVENTS}/${startRange};${endRange}`, {
      params
    });
  }
};
