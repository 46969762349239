import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getJobs(startRange, endRange, params) {
    return http.get(`${resources.JOBS}/${startRange};${endRange}`, {
      params
    });
  },

  getJobById(jobId, params) {
    return http.get(`${resources.JOBS}/${jobId}`, { params });
  }
};
