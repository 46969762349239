import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  changePassword(password_reset_key, new_password) {
    return http.put(`${resources.USER}/`, {
      password_reset_key,
      new_password
    });
  },
  verifyEmail(email_verification_key) {
    return http.put(`${resources.USER}/`, {
      email_verification_key
    });
  }
};
