import http from '../../services/http.service';
import resources from '../../config/api/resources';
import { clubMemberType } from './clubs-utils';

export default {
  getClubs(startRange, endRange, params) {
    return http.get(`${resources.STORE}/${startRange};${endRange}`, {
      params
    });
  },

  getClubById(serviceId, params) {
    return http.get(`${resources.STORE}/${serviceId}`, { params });
  },

  getClubMembers(params) {
    return http.get(`${resources.USER}/1;100`, { params }).then(members => {
      return Promise.resolve(
        members.filter(
          member => member.member_type === clubMemberType.executive
        )
      );
    });
  },

  getEventsByClubId(startRange, endRange, params) {
    return http.get(`${resources.EVENTS}/${startRange};${endRange}`, {
      params
    });
  }
};
