import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import './styles.css';
import UserUtils from '../../user-utils';
import UserService from '../../user-service';
import AppSpinnerComponent from '../../../../components/app-spinner/AppSpinner';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.key = this.props.match.params.key;
    this.state = {
      accent: '',
      newPassword: '',
      verifyPassword: '',
      valid: false,
      loading: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validatePasswords();
    });
  }

  validatePasswords() {
    const lengthValid = this.state.newPassword.length > 5;
    const matchValid = this.state.newPassword === this.state.verifyPassword;
    this.setState({ valid: lengthValid && matchValid });
  }

  onSubmit(e) {
    const hash = UserUtils.hash(this.state.newPassword);
    this.setState({ loading: true });
    UserService.changePassword(this.key, hash)
      .then(res => this.props.history.push('/user/forgot_password/success'))
      .catch(err => this.props.history.push('/user/forgot_password/expired'));
  }

  render() {
    const { t } = this.props;
    return (
      <Container className="user__reset">
        <Row className="user__reset__main">
          <Col>{t('user_reset_password')}</Col>
        </Row>
        <Row>
          <Col
            xs={{ size: 12 }}
            sm={{ size: 8, offset: 2 }}
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
          >
            <Form className="user__reset__form">
              <FormGroup>
                <Label for="newPassword">{t('user_new_password')}</Label>
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  disabled={this.state.loading}
                  onChange={this.onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="verifyPassword">
                  {t('user_verify_new_password')}
                </Label>
                <Input
                  type="password"
                  name="verifyPassword"
                  id="verifyPassword"
                  disabled={this.state.loading}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row className="user__reset__footer">
          <Col>
            <Button
              style={{
                backgroundColor: this.props.accent
              }}
              disabled={!this.state.valid || this.state.loading}
              onClick={this.onSubmit}
            >
              {t('user_update_password')}
            </Button>
          </Col>
        </Row>
        {this.state.loading && <AppSpinnerComponent centered />}
      </Container>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    accent: user.school.branding_color
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(ForgotPasswordForm);
