import React from 'react';

import './styles.css';

const AppPagination = ({ goBack, goNext, page, next }) => {
  return (
    <div className="app_pagination">
      <button onClick={goBack} disabled={page === 1}>
        <i className="material-icons">keyboard_arrow_left</i>
      </button>
      <span>{page}</span>
      <button onClick={goNext} disabled={!next}>
        <i className="material-icons">keyboard_arrow_right</i>
      </button>
    </div>
  );
};

export default AppPagination;
