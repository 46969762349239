import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../deals-service';
import { storeDetailsToSidebar, formatDealDate } from '../deals-utils';
import AppList from '../../../components/app-list/AppList';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppSidebarComponent from '../../../components/app-sidebar/AppSidebar';
import { reset as resetBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

class DealsStoreContainer extends React.Component {
  dealId;

  constructor(props) {
    super(props);
    this.dealId = this.props.match.params.dealId;

    this.state = {
      events: [],
      store: null,
      loading: true,
      deal: null
    };
  }

  componentDidMount() {
    let search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service
      .getDealById(this.dealId, search)
      .then(deal => {
        this.setState({ deal });
        return service.getStoreById(deal.store_id, search);
      })
      .then(store => {
        this.setState({ store });

        search.append('store_id', store.id);
        return service.getDeals(1, 100, search);
      })
      .then(deals => {
        this.props.dispatch(resetBreadCrumb());
        this.setState({
          loading: false,
          deals: deals.map(deal => {
            return {
              id: deal.id,
              heading: deal.title,
              linkTo: `/deals/${deal.id}`,
              description: deal.description,
              subheading: formatDealDate(deal, this.props.t),
              image: this.state.store.logo_url
            };
          })
        });
      });
  }
  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="page-padding-top page_deal">
        <p className="h1 strong">{this.state.store.name}</p>
        <Row>
          <Col xs={12} md={8} className="description">
            <article>
              <AppParagraph text={this.state.store.description} />
              <div className="service_details_list">
                <AppList items={this.state.deals} />
              </div>
            </article>
          </Col>
          <Col xs={12} md={{ size: 3, offset: 1 }} className="meta">
            <AppSidebarComponent
              {...storeDetailsToSidebar(this.state.store, null, this.props.t)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(DealsStoreContainer);
