import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../campaigns-service';
import { carouselData } from '../campaigns-utils';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppCarouselComponent from '../../../components/app-carousel/AppCarousel';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import { sanitizeHttpLink } from '../../../utils/text';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

class CampaignDetailsContainer extends React.Component {
  campaignId;

  constructor(props) {
    super(props);
    this.campaignId = this.props.match.params.campaignId;
    this.state = {
      campaigns: [],
      loading: true
    };
  }

  componentDidMount() {
    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service.getCampaignById(this.campaignId, search).then(campaign => {
      this.setState(
        {
          loading: false,
          campaign
        },
        () => {
          this.props.dispatch(
            updateBreadCrumb([
              {
                url: `campaigns/${campaign.id}`,
                label: campaign.name
              }
            ])
          );
        }
      );
    });
  }
  render() {
    const { t } = this.props;

    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="page-padding-top campaign page_campaign">
        <p className="h1 strong">{this.state.campaign.name}</p>
        <Row>
          <Col xs={12} />
          <Col xs={12} md={8}>
            <article>
              <main>
                {this.state.campaign.img_data.length ||
                this.state.campaign.vid_data.length ? (
                  <div className="page_campaign__hero__wrapper">
                    <AppCarouselComponent
                      items={carouselData(
                        this.state.campaign.img_data,
                        this.state.campaign.vid_data
                      )}
                    />
                  </div>
                ) : null}
              </main>
            </article>
          </Col>
          <Col xs={12} md={{ size: 3, offset: 1 }} className="meta">
            <AppParagraph text={this.state.campaign.description} />
            {this.state.campaign.link_data.length ? (
              <div>
                <p className="h2 meta__title">{t('links')}</p>
                <ul className="meta__links">
                  {this.state.campaign.link_data.map((link, index) => (
                    <li key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={sanitizeHttpLink(link[0])}
                      >
                        {link[0]}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(CampaignDetailsContainer);
