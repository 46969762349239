import http from './http.service';
import resources from '../config/api/resources';

const parseMarketPlaceUrl = marketUrl => {
  const host = 'https://play.google.com/store/apps/details?id=';
  const id = marketUrl.split('?id=')[1];

  return marketUrl ? `${host}${id}` : null;
};

const landingPageDomain = schoolNameAbbreviation => {
  let default_url = 'https://readyeducation.com';
  if (schoolNameAbbreviation)
    default_url = process.env.REACT_APP_REGION === 'CAN'
      ? `https://${schoolNameAbbreviation}.ca.campusapp.com`
      : `https://${schoolNameAbbreviation}.campusapp.com`;
  return default_url;
};

const parseSchool = school =>
  new Promise(resolve => {
    resolve({
      id: school.id,
      name: school.name,
      logo_url: school.logo_url,
      short_name: school.short_name,
      latitude: school.latitude,
      longitude: school.longitude,
      landing_page: school.abbreviation
        ? landingPageDomain(school.abbreviation)
        : null,
      promo_site_url: school.promo_site_url,
      app_logo_url: school.app_logo_url,
      branding_color: `#${school.branding_color}`,
      app_url_android: parseMarketPlaceUrl(school.app_url_android),
      app_url_ios: school.app_url_ios,
      campuses: school.campuses
    });
  });

const errorHandler = () => Promise.reject('Something went wrong');

export default {
  getSchoolByDomain(params) {
    return http
      .get(`${resources.SCHOOL}/`, { params }, false)
      .then(school => parseSchool(school))
      .catch(() => errorHandler());
  },

  getSchoolById(schoolId) {
    return http
      .get(`${resources.SCHOOL}/${schoolId}`, {}, true)
      .then(school => parseSchool(school))
      .catch(() => errorHandler());
  },

  getPersonas(startRange, endRange, params) {
    return http
      .get(`${resources.PERSONA}/${startRange};${endRange}`, { params }, true)
      .catch(() => errorHandler());
  }
};
