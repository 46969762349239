import { createStore, applyMiddleware, compose } from 'redux';

import reducer from './reducers';
import sagas, { sagaMiddleware } from './sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);
