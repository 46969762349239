export const snackBarActions = {
  SHOW: 'CORE_UI_SNACKBAR_SHOW',
  HIDE: 'CORE_UI_SNACKBAR_HIDE'
};

/**
 * @type 'default'
 * @param {type: string, message: string, timeOut: number} payload
 */
export function showSnackbar(payload) {
  return {
    type: snackBarActions.SHOW,
    payload
  };
}

export function hideSnackbar() {
  return {
    type: snackBarActions.HIDE
  };
}
