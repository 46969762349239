import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { setSearch } from '../store/actions';
import styles from './LocationDetails.module.scss';
import linkIcon from '../../common/assets/link.svg';
import { getLocationById } from '../store/selectors';
import emailIcon from '../../common/assets/ic_email.svg';
import phoneIcon from '../../common/assets/ic_phone.svg';
import { copyToClipboard } from '../../../../utils/text';
import pinIcon from '../../common/assets/ic_location_pin_lite.png';
import { showSnackbar } from '../../../../store/core/ui/snackbar/actions';
import {
  MetaCard,
  BackButton,
  SimpleCard,
  HoursCardLocation
} from '../../common/components';

class LocationDetails extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    const locationId = +match.params.locationId;

    const location = this.props.getLocationById(
      this.props.locations,
      locationId
    );

    this.props.setSearch(location.name);
  }

  handleCopyToClipboard = text => {
    copyToClipboard(text);

    const payload = {
      type: 'default',
      message: this.props.t('t_copied_to_clipboard')
    };
    this.props.showSnackbar(payload);
  };

  locationClick = catId => {
    this.props.history.push(`/maps/${catId}`);
  };

  backButtonClickHandler = () => {
    this.props.setSearch('');
    this.props.history.push('/maps');
  };

  render() {
    const { t, match } = this.props;
    const locationId = +match.params.locationId;
    const location = this.props.getLocationById(
      this.props.locations,
      locationId
    );

    return (
      <React.Fragment>
        <BackButton clickHandler={this.backButtonClickHandler}>
          {location.category_name}
        </BackButton>
        <hr className={styles.hr} />
        <Row>
          <Col>
            <HoursCardLocation t={t} schedule={location.schedule} />
            <hr className={styles.hr} />
          </Col>
        </Row>
        {location.links.length && location.links[0].url && (
          <Row>
            <Col>
              {location.links.map((link, idx) => (
                <React.Fragment key={idx}>
                  <a
                    href={link.url}
                    target="_blank"
                    className={styles.anchor}
                    rel="noopener noreferrer"
                  >
                    <SimpleCard
                      iconRight
                      icon={linkIcon}
                      text={`${link.label}`}
                      clickHandler={() => {}}
                    />
                    <hr className={styles.hr} />
                  </a>
                </React.Fragment>
              ))}
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <SimpleCard
              icon={pinIcon}
              text={location.address}
              clickHandler={this.handleCopyToClipboard}
            />
            <hr className={styles.hr} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SimpleCard
              icon={phoneIcon}
              text={location.phone}
              clickHandler={this.handleCopyToClipboard}
            />
            <hr className={styles.hr} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SimpleCard
              icon={emailIcon}
              text={location.email}
              clickHandler={this.handleCopyToClipboard}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MetaCard
              src={location.image_url}
              description={location.description}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ session, locations: { locations } }) {
  return {
    session,
    getLocationById,
    locations: locations.locations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearch: payload => dispatch(setSearch(payload)),
    showSnackbar: payload => dispatch(showSnackbar(payload))
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LocationDetails);
