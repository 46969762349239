export default {
  USER: 'ns_user',

  SCHOOL: 'school',

  DEALS: 'ns_deal',

  STORE: 'ns_store',

  EVENTS: 'ns_event',

  SEARCH: 'ns_search',

  EXAMS: 'ns_exam_time',

  MAPS: 'ns_campus_poi',

  JOBS: 'ns_job_listing',

  LINKS: 'ns_campus_link',

  SERVICES: 'campus_service',

  APP_CONFIG: 'ns_app_config',

  PERSONA: 'ns_school_persona',

  CAMPAIGNS: 'ns_school_campaign',

  ANNOUNCEMENT: 'ns_store_announcement',

  MAPS_CATEGORIES: 'ns_campus_poi_category'
};
