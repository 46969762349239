import ClubsDetailsContainer from './details/ClubsDetails';

export default [
  {
    exact: true,
    path: '/clubs',
    redirectTo: '/'
  },
  {
    exact: false,
    path: '/clubs/:clubId',
    component: ClubsDetailsContainer
  }
];
