export const diningActions = {
  LOADING_END: '[dining] LOADING_END',
  LOADING_START: '[dining] LOADING_START',

  GET_DINING: '[dining] GET_DINING',
  GET_DINING_FAIL: '[dining] GET_DINING_FAIL',
  GET_DINING_SUCCESS: '[dining] GET_DINING_SUCCESS',

  SET_SEARCH_VALUE: '[dining] SET_SEARCH_VALUE',

  GET_CATEGORIES: '[dining] GET_CATEGORIES',
  GET_CATEGORIES_FAIL: '[dining] GET_CATEGORIES_FAIL',
  GET_CATEGORIES_SUCCESS: '[dining] GET_CATEGORIES_SUCCESS'
};

/**
 *
 * @param {HttpParams} payload
 */
export function getDining(payload) {
  return {
    payload,
    type: diningActions.GET_DINING
  };
}

export function setSearch(query) {
  return {
    type: diningActions.SET_SEARCH_VALUE,
    payload: query
  };
}

export function loadingEnd() {
  return {
    type: diningActions.LOADING_END
  };
}

export function loadingStart() {
  return {
    type: diningActions.LOADING_START
  };
}
