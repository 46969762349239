import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './styles.css';
import { Col } from 'reactstrap';
import service from '../stores-service';
import { parse } from '../../../utils/router';
import Pagination from '../../../base/Pagination';
import AppList from '../../../components/app-list/AppList';
import AppSearchBarComponent from '../../../components/app-search/AppSearch';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppPagination from '../../../components/app-pagination/AppPagination';
import AppNoResultsComponent from '../../../components/app-no-results/AppNoResults';
import {
  storeToListComponent,
  categoryIdToTitle,
  storeCategories
} from '../stores-utils';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';

class StoresListContainer extends React.Component {
  pagination = new Pagination();
  storeCategoryId =
    Number(parse(this.props.location.search).get('category_ids')) ||
    storeCategories.clubs;

  constructor(props) {
    super(props);

    this.state = {
      stores: [],
      loading: false,
      search_str: null,
      pageTitle: 'stores'
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(search_str) {
    this.setState({ search_str }, () => this.fetch({ mutateState: true }));
  }

  goBack() {
    this.pagination.goBack();
    this.fetch({ mutateState: true });
  }

  goNext() {
    this.pagination.goNext();
    this.fetch({ mutateState: true });
  }

  componentDidMount() {
    this.setState(
      { pageTitle: categoryIdToTitle(Number(this.storeCategoryId)) },
      () => this.fetch()
    );
  }

  fetch(mutateState = false) {
    this.setState({ loading: true }, () => {
      const { startRange, endRange } = this.pagination;
      const search = new URLSearchParams();

      if (this.storeCategoryId !== storeCategories.webAppDeals) {
        search.append('category_id', this.storeCategoryId);
      }
      search.append('search_str', this.state.search_str);
      search.append('school_id', this.props.session.school.id);
      search.append('latitude', this.props.session.school.latitude);
      search.append('longitude', this.props.session.school.longitude);

      service.getStores(startRange, endRange, search).then(stores => {
        this.setState(
          {
            loading: false,
            stores: mutateState
              ? storeToListComponent(stores, this.storeCategoryId)
              : this.state.stores.concat(
                  storeToListComponent(stores, this.storeCategoryId)
                )
          },
          () =>
            this.props.dispatch(
              updateBreadCrumb([
                {
                  url: `stores${this.props.location.search}`,
                  label: this.state.pageTitle
                }
              ])
            )
        );
      });
    });
  }

  render() {
    const { t } = this.props;
    const { pageTitle } = this.state;

    const searchBar = (
      <AppSearchBarComponent
        placeholder={t(`${pageTitle}_search_placeholder`)}
        onSearch={this.handleSearch}
      />
    );

    const storesList = (
      <div>
        <AppList items={this.state.stores} />
        <AppPagination
          goBack={this.goBack}
          goNext={this.goNext}
          page={this.pagination.currentPage}
          next={this.pagination.maxPerPage === this.state.stores.length}
        />
      </div>
    );
    return (
      <div className="services_page page-padding-top">
        <p className="h1 strong text-center">{t(pageTitle)}</p>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          <div className="search">{searchBar}</div>
          {this.state.loading ? (
            <AppSpinnerComponent centered />
          ) : (
            <div>
              {this.state.stores.length ? (
                storesList
              ) : (
                <div className="no_results">
                  <AppNoResultsComponent query={this.state.search_str} />
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(StoresListContainer);
