import React from 'react';
import { compose } from 'redux';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../jobs-service';
import Pagination from '../../../base/Pagination';
import { jobToListComponent } from '../jobs-utils';
import AppList from '../../../components/app-list/AppList';
import AppSearchBarComponent from '../../../components/app-search/AppSearch';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppNoResultsComponent from '../../../components/app-no-results/AppNoResults';
import AppPagination from '../../../components/app-pagination/AppPagination';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';

class JobsListContainer extends React.Component {
  pagination = new Pagination();

  constructor(props) {
    super(props);

    this.state = {
      jobs: [],
      loading: false,
      search_str: null
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(search_str) {
    this.setState({ search_str }, () => this.fetch({ mutateState: true }));
  }

  goBack() {
    this.pagination.goBack();
    this.fetch({ mutateState: true });
  }

  goNext() {
    this.pagination.goNext();
    this.fetch({ mutateState: true });
  }

  componentDidMount() {
    this.props.dispatch(
      updateBreadCrumb([
        {
          url: 'jobs',
          label: this.props.t('jobs')
        }
      ])
    );
    this.fetch();
  }

  fetch(mutateState = false) {
    this.setState({ loading: true }, () => {
      const { startRange, endRange } = this.pagination;
      const search = new URLSearchParams();
      search.append('search_str', this.state.search_str);
      search.append('school_id', this.props.session.school.id);

      service.getJobs(startRange, endRange, search).then(jobs => {
        this.setState({
          loading: false,
          jobs: mutateState
            ? jobToListComponent(jobs)
            : this.state.jobs.concat(jobToListComponent(jobs))
        });
      });
    });
  }

  render() {
    const { t } = this.props;

    const searchBar = (
      <AppSearchBarComponent
        placeholder={t('jobs_search_placeholder')}
        onSearch={this.handleSearch}
      />
    );

    const jobsList = <AppList items={this.state.jobs} />;

    return (
      <div className="jobs_page page-padding-top">
        <p className="h1 strong text-center">{t('jobs')}</p>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          <div className="search">{searchBar}</div>
          {this.state.loading ? (
            <AppSpinnerComponent centered />
          ) : (
            <div>
              {this.state.jobs.length ? (
                <div>
                  {jobsList}
                  <AppPagination
                    goBack={this.goBack}
                    goNext={this.goNext}
                    page={this.pagination.currentPage}
                    next={this.pagination.maxPerPage === this.state.jobs.length}
                  />
                </div>
              ) : (
                <div className="no_results">
                  <AppNoResultsComponent query={this.state.search_str} />
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(JobsListContainer);
