import { appSidebarTypes } from '../../components/app-sidebar/AppSidebar';

export const detailsToSidebar = club => {
  return {
    hero: {
      src: club.logo_url,
      alt: club.name
    },
    map: {
      latitude: club.latitude,
      longitude: club.longitude
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_email',
        type: appSidebarTypes.email,
        value: club.email
      },
      {
        id: 2,
        title: 'sidebar_phone',
        type: appSidebarTypes.phone,
        value: club.phone
      },
      {
        id: 3,
        title: 'sidebar_website',
        type: appSidebarTypes.url,
        value: club.website
      }
    ]
  };
};
