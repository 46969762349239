import EventsListContainer from './list/EventsList';
import EventDetailsContainer from './details/EventDetails';

export default [
  {
    path: '/events',
    exact: true,
    component: EventsListContainer
  },
  {
    exact: false,
    path: '/events/:eventId',
    component: EventDetailsContainer
  }
];
