import React from 'react';

import './styles.css';
import service from '../../navbar-service';

import AppSpinnerComponent from '../../../app-spinner/AppSpinner';
import AppParagraph from '../../../app-paragraph/AppParagraph';

class AppNavSecurityPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tile: null
    };
    this.serviceId = this.props.tileInfo.extra_info.link_params.id;
  }
  componentDidMount() {
    service.getServiceById(this.serviceId).then(tile => {
      this.setState({
        loading: false,
        tile
      });
    });
  }

  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="app_security_popover app_navigation_popover">
        <div
          className="app_security_popover_hero"
          style={{ backgroundImage: 'url(' + this.state.tile.logo_url + ')' }}
        />
        <div className="app_security_popover__content">
          <p className="h2">{this.state.tile.name}</p>
          <AppParagraph text={this.state.tile.description} />
          <p>{this.state.tile.phone}</p>
        </div>
      </div>
    );
  }
}

export default AppNavSecurityPopover;
