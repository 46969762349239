export const parse = query => {
  return new URLSearchParams(query);
};

export const contains = (query, key) => {
  /**
   * contains(?foo=bar, foo) true
   * contains(?foo=bar, gold) false
   */
  return parse(query).has(key);
};
