import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './styles.css';
import modal_hero from './assets/modal_download.png';

const AppDownloadModal = props => {
  const { isOpen, toggle, t, iosUrl, androidUrl, accent } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      {/* <button onClick={toggle} className="modal__close">
        <i className="material-icons">close</i>
      </button> */}
      <ModalHeader
        tag="div"
        className="moda__header_bs"
        style={{ backgroundColor: accent }}
      >
        <div className="modal__header">
          <img alt="Download App" className="img-fluid" src={modal_hero} />
        </div>
      </ModalHeader>
      <ModalBody className="modal__body">
        <p className="h4">{t('download_app_modal_heading')}</p>
        <p className="muted">{t('download_app_modal_body')}</p>
      </ModalBody>
      <ModalFooter className="modal__footer">
        <a
          onClick={toggle}
          href={iosUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="modal__footer_badge_ios"
            src={t('app_store_download_badge_apple_store_url')}
            alt={t('app_store_download_badge_apple_store_text')}
          />
        </a>
        <a
          onClick={toggle}
          href={androidUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="modal__footer_badge_android"
            src={t('app_store_download_badge_google_play_url')}
            alt={t('app_store_download_badge_google_play_text')}
          />
        </a>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ session }) => {
  return {
    accent: session.school.branding_color
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(AppDownloadModal);
