import { range, get as _get } from 'lodash';

import { getSecondsSinceMidnight } from '../../../../utils/dates';

const currentTime = getSecondsSinceMidnight();

export const today = Number(new Date().getDay());

export const startsInThirtyMinutes = ({ start_time }) =>
  start_time > currentTime && currentTime + 1800 > start_time;

export const isRunning = ({ start_time, end_time }) => {
  return start_time <= currentTime && end_time >= currentTime;
};

export function getScheduleForDay(schedule, day = new Date().getDay()) {
  return schedule.find(s => s.day === day);
}

export function locationToMarker(locations) {
  return locations.map(location => {
    return {
      locationId: location.id,
      color: location.categoryColor,
      icon: location.categoryMarkerIcon,
      title: location.name,
      address: location.address,
      latitude: location.latitude,
      longitude: location.longitude
    };
  });
}

export function findLocationsBy(locations, query) {
  const filters = ['name', 'category_name', 'short_name'];
  const matching = (location, filter) =>
    location[filter].toLowerCase().includes(query.toLowerCase());

  return locations.filter(location =>
    filters.some(filter => matching(location, filter))
  );
}

export function addMissingDays(schedule) {
  const daysOfTheWeek = range(1, 8);

  return daysOfTheWeek.map(day => {
    const daySchedule = getScheduleForDay(schedule, day);
    const items = _get(daySchedule, 'items', []);

    return { day, items };
  });
}

export const dayMapping = {
  1: 't_shared_monday',
  2: 't_shared_tuesday',
  3: 't_shared_wednesday',
  4: 't_shared_thursday',
  5: 't_shared_friday',
  6: 't_shared_saturday',
  7: 't_shared_sunday'
};

export const createMarkerSvgMarkup = (fill, svgDData) => {
  return `
  <svg width="34" height="35" xmlns="http://www.w3.org/2000/svg" style="vector-effect: non-scaling-stroke;">
  <g>
    <ellipse ry="17.600563" rx="16.812492" id="svg_8" cy="17.581052" cx="17.062499" stroke-opacity="null" stroke-width="0" stroke="#000" fill="#fff"/>
    <ellipse ry="15.551215" rx="15.124999" id="svg_7" cy="17.581051" cx="17.0625" stroke-opacity="null" stroke-width="0" stroke="#000" fill="${fill}"/>
    <path style="transform: scale(0.7) translate(12.5px, 12px); fill:white;" d="${svgDData}"></path>
  </g>
  </svg>
  `;
};

export function filterItemsBasedOnCurrentTime(items) {
  if (!items) {
    return [];
  }

  const startTimes = items.map(i => i.start_time);
  const earliestTime = Math.min(...startTimes);
  const endTimes = items.map(i => i.end_time);
  const latestTime = Math.max(...endTimes);

  const areAllClosed = currentTime >= latestTime;
  const noneHasStarted = currentTime <= earliestTime;
  const areRunning = items.some(isRunning);
  const startsSoon = items.some(startsInThirtyMinutes);

  if (areAllClosed) {
    return items.find(i => i.end_time === latestTime);
  } else if (noneHasStarted) {
    return items.find(i => i.start_time === earliestTime);
  } else if (areRunning) {
    return items.filter(isRunning)[0];
  } else if (startsSoon) {
    return items.filter(startsInThirtyMinutes)[0];
  }

  return [];
}

export * from './category-to-svg-d';
