import CampaignsListContainer from './list/CampaignsList';
import CampaignDetailsContainer from './details/CampaignDetails';

export default [
  {
    path: '/campaigns',
    exact: true,
    component: CampaignsListContainer
  },
  {
    path: '/campaigns/:campaignId',
    exact: false,
    component: CampaignDetailsContainer
  }
];
