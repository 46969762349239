import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';

import './styles.css';

class UserContent extends React.Component {
  main;
  icon;
  sub;
  constructor(props) {
    super(props);
    this.main = this.props.main;
    this.icon = this.props.icon;
    this.sub = this.props.sub;
  }
  render() {
    const { t } = this.props;
    return (
      <Container className="user__content">
        <Row className="user__content__main">
          <Col>{t(this.main)}</Col>
        </Row>
        <Row className="user__content__icon">
          <Col>
            <i className="material-icons">{this.icon}</i>
          </Col>
        </Row>
        {this.sub && (
          <Row className="user__content__sub">
            <Col>{t(this.sub)}</Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(UserContent);
