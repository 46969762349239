import React from 'react';
import { compose } from 'redux';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import service from './campus-guide-service';
import ExperienceModal from './components/ExperienceModal';
import storage, { STORAGE_KEYS } from '../../utils/storage';
import AppButton from '../../components/app-button/AppButton';
import AppTileComponent from '../../components/app-tile/AppTile';
import { tileTypeToUrl, isExternalLink } from '../../utils/tiles';
import AppSpinnerComponent from '../../components/app-spinner/AppSpinner';
import AppCarouselComponent from '../../components/app-carousel/AppCarousel';
import { reset } from '../../components/app-breadcrumb/app-breadcrumb-actions';

import './styles.scss';

class CampusGuideContainer extends React.Component {
  constructor(props) {
    super(props);
    const storedPersona = storage.get(STORAGE_KEYS.defaultPersona);
    const defaultPersona = storedPersona
      ? this.props.session.personas.filter(p => p.id === +storedPersona)[0]
      : this.props.session.personas[0];
    this.state = {
      modal: false,
      loading: false,
      defaultTiles: this.props.session.appConfig.defaultTiles,
      personalizedGuide: this.props.session.appConfig.personalized_guide,
      currentExperience: defaultPersona
    };

    this.updatePersona = this.updatePersona.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(reset());
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  updatePersona(persona) {
    storage.set(STORAGE_KEYS.defaultPersona, persona.id);

    window.location.reload();
  }

  getTilesByPersona() {
    this.setState({ loading: true });

    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);
    search.append('school_persona_id', this.state.currentExperience.id);

    service
      .getTilesByPersonaId(search)
      .then(({ personalized_guide, defaultTiles }) => {
        this.setState({
          loading: false,
          defaultTiles: defaultTiles,
          personalizedGuide: personalized_guide
        });
      });
  }

  render() {
    const { t } = this.props;
    const { personas } = this.props.session;
    const { name, logo_url, branding_color } = this.props.session.school;
    const defaultTiles = this.state.defaultTiles;
    const personalized_guide = this.state.personalizedGuide;
    const accentColor = branding_color;

    let carouselContent = [
      { src: logo_url, alt: `${name} banner`, type: 'image' }
    ];
    if (this.props.featuredTiles) {
      carouselContent = [
        ...carouselContent,
        ...this.props.featuredTiles.map(tile => {
          return {
            type: 'image',
            src: tile.img_url,
            alt: 'featured tile',
            caption: tile.name,
            linkTo: tileTypeToUrl(tile.extra_info),
            external: isExternalLink(tile.extra_info)
          };
        })
      ];
    }

    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <Container className="campus_guide_page">
        <Row>
          <Col xs={12} md={6}>
            <header>
              <p className="h1 strong welcome__message">
                {t('campus_guide_welcome_text', { schoolName: name })}
              </p>
            </header>
            {personas.length > 1 ? (
              <main>
                <strong>{t('campus_guide_experience')}</strong>
                <AppButton nopadding transparent onClick={this.toggleModal}>
                  <span className="welcome__message__btn">
                    {this.state.currentExperience.name}
                  </span>
                </AppButton>
              </main>
            ) : null}
            <ExperienceModal
              t={this.props.t}
              personas={personas}
              accent={accentColor}
              isOpen={this.state.modal}
              buttonClick={this.updatePersona}
              onToggleModal={this.toggleModal}
            />
          </Col>
          <Col xs={12} md={6}>
            <AppCarouselComponent items={carouselContent} />
          </Col>
        </Row>
        <div className="category">
          <Row>
            {defaultTiles.map(tile => {
              return (
                <Col
                  xs={6}
                  md={3}
                  lg={2}
                  key={tile.id}
                  className="category__tiles"
                >
                  <AppTileComponent
                    className="card"
                    {...tile}
                    linkTo={tileTypeToUrl(tile.extra_info)}
                    external={isExternalLink(tile.extra_info)}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        {personalized_guide.map(guide => {
          return (
            <div key={guide.id} className="category">
              <Row className="category__name">
                <Col>
                  <p className="h2 regular">{guide.name}</p>
                </Col>
              </Row>
              <Row>
                {guide.tiles.map(tile => {
                  return (
                    <Col
                      xs={6}
                      md={3}
                      lg={2}
                      key={tile.id}
                      className="category__tiles"
                    >
                      <AppTileComponent
                        className="card"
                        {...tile}
                        linkTo={tileTypeToUrl(tile.extra_info)}
                        external={isExternalLink(tile.extra_info)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </Container>
    );
  }
}

const mapStateToProps = ({ session }) => {
  return {
    featuredTiles: session.featuredTiles
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(CampusGuideContainer);
