export const locationsActions = {
  LOADING_END: '[dining] LOADING_END',
  LOADING_START: '[dining] LOADING_START',

  GET_LOCATIONS: '[locations] GET_LOCATIONS',
  GET_LOCATIONS_FAIL: '[locations] GET_LOCATIONS_FAIL',
  GET_LOCATIONS_SUCCESS: '[locations] GET_LOCATIONS_SUCCESS',

  SET_SEARCH_VALUE: '[locations] SET_SEARCH_VALUE',

  GET_CATEGORIES: '[locations] GET_CATEGORIES',
  GET_CATEGORIES_FAIL: '[locations] GET_CATEGORIES_FAIL',
  GET_CATEGORIES_SUCCESS: '[locations] GET_CATEGORIES_SUCCESS'
};

export function getLocations() {
  return {
    type: locationsActions.GET_LOCATIONS
  };
}

export function loadingEnd() {
  return {
    type: locationsActions.LOADING_END
  };
}

export function loadingStart() {
  return {
    type: locationsActions.LOADING_START
  };
}

export function setSearch(query) {
  return {
    type: locationsActions.SET_SEARCH_VALUE,
    payload: query
  };
}
