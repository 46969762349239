import { call, put, takeEvery } from 'redux-saga/effects';
import { Translation } from 'react-i18next';
import React from 'react';

import service from '../../../campus-map.service';
import { toBase64 } from '../../../../../utils/svg';
import { campusMapLocationType } from '../../../campus-map-utils';
import { diningActions, loadingEnd, loadingStart } from '../actions';
import { showSnackbar } from '../../../../../store/core/ui/snackbar/actions';

import {
  categoryToSvgData,
  createMarkerSvgMarkup
} from '../../../common/utils';

function* fetchDining(action) {
  yield put(loadingStart());

  let dining = [];
  let categories = [];
  try {
    categories = yield call(service.getLocationCategories, action.payload);
  } catch (e) {
    yield put(loadingEnd());
    yield put(
      showSnackbar({
        type: 'danger',
        message: <Translation>{t => t('t_something_went_wrong')}</Translation>
      })
    );
    yield put({ type: diningActions.GET_CATEGORIES_FAIL, payload: e });
  }

  categories = categories.filter(
    c => c.category_type_id === campusMapLocationType.dining
  );
  yield put({
    type: diningActions.GET_CATEGORIES_SUCCESS,
    payload: categories
  });

  try {
    dining = yield call(service.getLocations, 1, 1000, action.payload);
    const diningCategories = categories.map(c => c.id);
    dining = dining.filter(c => diningCategories.includes(c.category_id));
  } catch (e) {
    yield put(loadingEnd());
    yield put(
      showSnackbar({
        type: 'danger',
        message: <Translation>{t => t('t_something_went_wrong')}</Translation>
      })
    );
    yield put({ type: diningActions.GET_DINING_FAIL, payload: e });
  }

  const categoryMarkerIcon = {};
  categories.forEach(c => {
    categoryMarkerIcon[c.id] = {
      icon: toBase64(
        createMarkerSvgMarkup(`#${c.color}`, categoryToSvgData[c.img_url])
      ),
      color: `#${c.color}`
    };
  });

  dining.forEach(l => {
    l['categoryMarkerIcon'] = categoryMarkerIcon[l.category_id].icon;
    l['categoryColor'] = categoryMarkerIcon[l.category_id].color;
  });

  yield put({
    type: diningActions.GET_DINING_SUCCESS,
    payload: dining
  });

  yield put(loadingEnd());
}

function* diningSagas() {
  yield takeEvery(diningActions.GET_DINING, fetchDining);
}

export default diningSagas;
