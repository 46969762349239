import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import React from 'react';

import UserService from '../user-service';
import UserContent from '../components/user-content/UserContent';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';

class VerifyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.key = this.props.match.params.key;
    this.state = {
      loading: true,
      main: 'user_account_verified',
      icon: 'done',
      sub: null
    };
  }

  componentDidMount() {
    UserService.verifyEmail(this.key)
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({
          main: 'user_verification_failed',
          icon: 'clear',
          sub: 'user_verification_failed_sub',
          loading: false
        });
      });
  }

  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <UserContent
        main={this.state.main}
        icon={this.state.icon}
        sub={this.state.sub}
      />
    );
  }
}

export default compose(withTranslation())(VerifyContainer);
