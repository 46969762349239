import { appSidebarTypes } from '../../components/app-sidebar/AppSidebar';

export const storeCategories = {
  clubs: -1,
  service: 19,
  athletic: 16,
  webAppDeals: 17 // this is not a real store_id and is meant to be used only in this app
};

export const categoryIdToTitle = categoryId => {
  if (categoryId === storeCategories.clubs) {
    return 'clubs';
  }
  if (categoryId === storeCategories.service) {
    return 'services';
  }
  if (categoryId === storeCategories.athletic) {
    return 'athletics';
  }
  if (categoryId === storeCategories.webAppDeals) {
    return 'deals';
  }
};

export const storeToListComponent = (stores, storeCategoryId) => {
  return stores.map(store => {
    return {
      id: store.id,
      heading: store.name,
      image: store.logo_url,
      description: store.description,
      linkTo: `/${storeCategoryIdToRoute(Number(storeCategoryId))}/${store.id}`
    };
  });
};

export const storeCategoryIdToRoute = categoryParam => {
  switch (categoryParam) {
    case storeCategories.webAppDeals:
      return 'deals';

    case storeCategories.service:
      return 'services';

    case storeCategories.clubs:
    case -1:
      return 'clubs';

    case storeCategories.athletic:
      return 'athletics';

    default:
      return null;
  }
};

export const setRedirectLink = ({ category_id }) => {
  let resource;

  if (category_id === storeCategories.clubs || category_id === 0) {
    resource = 'clubs';
  }
  if (category_id === storeCategories.athletic) {
    resource = 'athletics';
  }

  return Promise.resolve(resource);
};

export const detailsToSidebar = (store, executives = []) => {
  return {
    hero: {
      src: store.logo_url,
      alt: store.name
    },
    map: {
      latitude: store.latitude,
      longitude: store.longitude
    },
    list: {
      heading: 'clubs_sidebar_executives_header',
      data: executives.map(exec => {
        return {
          id: exec.id,
          avatar: exec.avatar_thumb_url,
          label: `${exec.firstname} ${exec.lastname}`,
          subheading: exec.member_position
        };
      })
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_email',
        type: appSidebarTypes.email,
        value: store.email
      },
      {
        id: 2,
        title: 'sidebar_phone',
        type: appSidebarTypes.phone,
        value: store.phone
      },
      {
        id: 3,
        title: 'sidebar_website',
        type: appSidebarTypes.url,
        value: store.website
      },
      {
        id: 4,
        title: 'sidebar_location',
        type: appSidebarTypes.location,
        value: store.address
      }
    ]
  };
};
