import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  DropdownMenu
} from 'reactstrap';

import './styles.scss';
import AppButton from '../../../app-button/AppButton';
import AppNavSecurityPopover from '../app-nav-security-popover/AppNavSecurityPopover';
import AppNavAnnouncementPopover from '../announcement-popover/AppNavAnnouncementPopover';

const AppNavLinks = ({
  location,
  securityTile,
  toggleDownloadModal,
  school,
  t
}) => {
  return (
    <Nav navbar className="app_nav_links">
      <NavItem className="app_nav_links__item">
        <NavLink tag="span">
          <Link to="/" className={location.pathname === '/' ? 'active' : null}>
            {t('browse')}
          </Link>
        </NavLink>
      </NavItem>
      {securityTile ? (
        <UncontrolledDropdown nav inNavbar className="app_nav_links__item">
          <DropdownToggle nav data-toggle="collapse">
            <i className="material-icons">security</i>
          </DropdownToggle>
          <DropdownMenu right>
            <AppNavSecurityPopover tileInfo={securityTile} />
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null}
      <UncontrolledDropdown nav inNavbar className="app_nav_links__item">
        <DropdownToggle nav>
          <i className="material-icons">email</i>
        </DropdownToggle>
        <DropdownMenu right>
          <AppNavAnnouncementPopover school={school} />
        </DropdownMenu>
      </UncontrolledDropdown>
      <NavItem className="app_nav_links__item">
        <NavLink tag="span">
          <AppButton nopadding transparent onClick={toggleDownloadModal}>
            <i className="material-icons">smartphone</i>
          </AppButton>
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default withTranslation()(AppNavLinks);
