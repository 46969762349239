import React from 'react';
import classNames from 'classnames';

function AppButton(props) {
  const { children, disabled, type, onClick } = props;
  const buttonType = type ? type : 'button';

  const className = classNames('button', {
    'button--primary': props.primary,
    'button--info': props.info,
    'button--block': props.block,
    'button--danger': props.danger,
    'button--warning': props.warning,
    'button--hover': props.onClick,
    'button--accent': props.accent,
    'button--animate': props.animate,
    'button--no-padding': props.nopadding,
    'button--transparent': props.transparent
  });

  return (
    <button
      type={buttonType}
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={props.accent ? { backgroundColor: props.accent } : null}
    >
      {children}
    </button>
  );
}

export default AppButton;
