import {
  tileTypeToUrl,
  supportedTiles,
  tileLinkUrlToContentType
} from '../../utils/tiles';

export const linksToList = links => {
  return links.map(link => {
    return {
      id: link.id,
      heading: link.name,
      image: link.img_url,
      external: link.link_type === 0,
      description: link.description,
      linkTo: tileTypeToUrl(link)
    };
  });
};

export const filterOutSecurityTile = links => {
  return links.filter(
    link => !link.link_url.includes('campus_security_service')
  );
};

export const webFeaturesOnly = links => {
  return links.filter(
    link =>
      link.link_type === 0 ||
      supportedTiles.includes(tileLinkUrlToContentType(link.link_url))
  );
};
