import React from 'react';
import { withTranslation } from 'react-i18next';

import './styles.css';

const AppNoResultsComponent = ({ query, t }) => {
  const message = !query ? t('no_data') : t('no_results', { query });

  return (
    <div className="app_no_results">
      <i className="material-icons">search</i>
      <p>{message}</p>
    </div>
  );
};

export default withTranslation()(AppNoResultsComponent);
