import { createSelector } from 'reselect';

const locationsByCategory = (locations, categoryId) => {
  return locations.filter(l => l.category_id === +categoryId);
};

const locationsById = (locations, locationId) => {
  return locations.find(l => l.id === +locationId);
};

const categoryById = (category, categoryId) => {
  return category.find(c => c.id === +categoryId);
};

export const getLocationByCategory = createSelector(
  locationsByCategory,
  locations => locations
);

export const getLocationById = createSelector(
  locationsById,
  location => location
);

export const getCategoryById = createSelector(
  categoryById,
  category => category
);
