import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';

import i18nDetector from './i18n-detector';
import { production, staging } from '../environment';

const lngDetector = new LngDetector();
lngDetector.addDetector(i18nDetector);

i18n
  .use(XHR)
  .use(lngDetector)
  .init({
    detection: {
      order: ['i18nDetector']
    },
    backend: {
      loadPath:
        production || staging
          ? '/dist/locales/{{lng}}/{{ns}}.json'
          : 'locales/{{lng}}/{{ns}}.json'
    },
    load: 'currentOnly',
    fallbackLng: false,
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    // react i18next special options (optional)
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: false
    }
  });

export default i18n;
