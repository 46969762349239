import { snackBarActions } from './actions';

const defaults = {
  timeOut: 1500
};

const initialState = {
  show: false,
  message: null,
  type: null,
  timeOut: defaults.timeOut
};

export default function(state = initialState, action) {
  switch (action.type) {
    case snackBarActions.SHOW:
      const { type, message, timeOut } = action.payload;

      return {
        ...state,
        type,
        message,
        show: true,
        timeOut: timeOut ? timeOut : defaults.timeOut
      };

    case snackBarActions.HIDE:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
}
