import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import styles from './CampusMapList.module.css';
import { SideBarSearch } from '../../common/components';
import { getLocations, setSearch } from '../store/actions';
import LocationsList from '../locations-list/LocationsList';
import LocationDetails from '../location-details/LocationDetails';
import AppMapComponent from '../../../../components/app-map/AppMap';
import SidebarLayout from '../../../../layouts/sidebar/SidebarLayout';
import LocationCategoriesList from '../categories-list/LocationCategoriesList';
import AppSpinnerComponent from '../../../../components/app-spinner/AppSpinner';
import { update } from '../../../../components/app-breadcrumb/app-breadcrumb-actions';
class CampusMapListContainer extends React.Component {
  componentDidMount() {
    this.props.updateBreadCrumb([
      {
        url: 'maps',
        label: this.props.t('maps')
      }
    ]);

    if (this.props.shouldFetch) {
      this.fetch();
    }
  }

  fetch() {
    this.props.getLocations();
  }

  onHandleSearch = query => {
    const pathname = query ? '/maps/-1' : '/maps';

    this.props.history.push({
      pathname
    });

    this.props.setSearch(query);
  };

  render() {
    const {
      query,
      session: {
        school: { latitude, longitude }
      },
      markers,
      loading
    } = this.props;

    if (loading) {
      return <AppSpinnerComponent centered />;
    }

    return (
      <SidebarLayout
        content={
          <Map markers={markers} latitude={latitude} longitude={longitude} />
        }
        sidebar={<SideBar query={query} handleSearch={this.onHandleSearch} />}
      />
    );
  }
}

const SideBar = ({ query, handleSearch }) => {
  return (
    <React.Fragment>
      <Row className={styles.searchbox}>
        <Col xs={12}>
          <SideBarSearch value={query} onSearch={handleSearch} />
        </Col>
      </Row>
      <Route path="/maps" exact component={LocationCategoriesList} />
      <Route path="/maps/:categoryId" exact component={LocationsList} />
      <Route path="/maps/:categoryId/:locationId" component={LocationDetails} />
    </React.Fragment>
  );
};

const Map = ({ latitude, longitude, markers }) => (
  <AppMapComponent
    zoom={15}
    markers={markers}
    center={{ lat: latitude, lng: longitude }}
  />
);

function mapStateToProps({ session, locations: { locations, ui } }) {
  return {
    session,
    query: ui.query,
    loading: ui.loading,
    markers: locations.markers,
    shouldFetch: !locations.loaded
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLocations: () => dispatch(getLocations()),
    setSearch: payload => dispatch(setSearch(payload)),
    updateBreadCrumb: payload => dispatch(update(payload))
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CampusMapListContainer);
