import React from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.css';

const AppNavSearch = ({ handleQuery, schoolName, t }) => {
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <i className="material-icons app_master_search_icon">search</i>
      </InputGroupAddon>
      <Input
        type="text"
        className="navbar__search"
        placeholder={t('search_placeholder', { placeholderText: schoolName })}
        onChange={event => handleQuery(event.target.value)}
      />
    </InputGroup>
  );
};

export default withTranslation()(AppNavSearch);
