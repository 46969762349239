import { sortBy, flatten } from 'lodash';

import http from '../../services/http.service';
import resources from '../../config/api/resources';
import {
  validTile,
  INVALID_RANK,
  CAMPUS_SECURITY_LINK
} from './campus-guide-utils';

export default {
  getTilesByPersonaId(params) {
    const request = http.get(`${resources.APP_CONFIG}/`, { params }, false);
    return request.then(config => {
      const sortedGuides = sortBy(
        config.personalized_guide,
        guide => guide.rank
      );

      const personalized_guide = sortedGuides
        .filter(guide => guide.tiles.length)
        .map(guide => {
          return {
            ...guide,
            tiles: sortBy(guide.tiles, tile => tile.rank)
          };
        });

      return new Promise(resolve => {
        const categoryZeroTiles = flatten(
          personalized_guide.filter(g => g.id === 0).map(g => g.tiles)
        ).filter(tile => validTile(tile));

        const obj = {
          defaultTiles: categoryZeroTiles.filter(
            tile => tile.featured_rank === INVALID_RANK
          ),

          featuredTiles: categoryZeroTiles.filter(
            tile => tile.featured_rank > INVALID_RANK
          ),

          personalized_guide: flatten(
            personalized_guide
              .filter(g => g.id !== 0)
              .map(g => {
                return {
                  ...g,
                  tiles: g.tiles.filter(tile => validTile(tile))
                };
              })
          ),

          securityTile: flatten(
            personalized_guide.map(guide => guide.tiles)
          ).find(tile => tile.extra_info.link_url === CAMPUS_SECURITY_LINK),

          enforced_locale: config.enforced_locale
        };

        resolve(obj);
      });
    });
  },

  getServiceById(serviceId) {
    return http.get(`${resources.SERVICES}/${serviceId}`, {}, false);
  }
};
