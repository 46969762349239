import { HashRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/body/stylesheet.css';
import './assets/fonts/icon/material-icons.css';
import './App.scss';

import AppContainer from './AppContainer';
import UserContainer from './containers/user/User';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/user" component={UserContainer} />
          <Route path="/" component={AppContainer} />
        </Switch>
      </HashRouter>
    );
  }
}

export default connect()(App);
