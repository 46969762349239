import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Redirect } from 'react-router';

import './styles.css';
import service from '../stores-service';
import { contains } from '../../../utils/router';
import { fromEpoch } from '../../../utils/dates';
import Pagination from '../../../base/Pagination';
import AppList from '../../../components/app-list/AppList';
import AppSidebarComponent from '../../../components/app-sidebar/AppSidebar';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

import {
  setRedirectLink,
  detailsToSidebar,
  storeCategories
} from '../stores-utils';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';

class StoreDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.pagination = new Pagination();
    this.loadAndRedirect = false;

    this.state = {
      events: [],
      store: null,
      loading: true,
      redirectTo: null
    };
  }

  loadEvents(storeId) {
    const { startRange, endRange } = this.pagination;
    const search = new URLSearchParams();
    search.append('store_id', storeId);
    search.append('school_id', this.props.session.school.id);

    return service.getEventsByStoreId(startRange, endRange, search);
  }

  componentDidMount() {
    const { storeId } = this.props.match.params;

    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service
      .getStoreById(storeId, search)
      .then(store => {
        this.setState({ store });
        this.loadAndRedirect =
          contains(this.props.location.search, 'redirect') &&
          store.category_id !== storeCategories.service;

        return this.loadAndRedirect
          ? setRedirectLink(store)
          : this.loadEvents(storeId);
      })
      .then(response => {
        this.loadAndRedirect
          ? this.setState({
              loading: false,
              redirectTo: `/${response}/${storeId}`
            })
          : this.setState({
              loading: false,
              events: response.map(event => {
                return {
                  id: event.id,
                  heading: event.title,
                  linkTo: `/events/${event.id}`,
                  description: event.description,
                  subheading: fromEpoch(event.start),
                  image: event.poster_thumb_url
                };
              })
            });
        const search = new URLSearchParams();
        search.append('group_id', this.state.store.group_id);

        return this.state.club.group_id === -1
          ? Promise.resolve([])
          : service.getClubMembers(search);
      })
      .then(executives => {
        this.setState(
          {
            loading: false,
            executives
          },
          () => {
            if (!this.state.redirectTo) {
              this.props.dispatch(
                updateBreadCrumb([
                  {
                    url: `stores/${this.state.store.id}`,
                    label: this.state.store.name
                  }
                ])
              );
            }
          }
        );
      });
  }

  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : this.state.redirectTo ? (
      <Redirect to={this.state.redirectTo} />
    ) : (
      <div className="page-padding-top">
        <p className="h1 strong">{this.state.store.name}</p>
        <Row>
          <Col xs={12} md={8} className="description">
            <article>
              <AppParagraph text={this.state.store.description} />
              <div className="service_details_list">
                <AppList items={this.state.events} />
              </div>
            </article>
          </Col>
          <Col xs={12} md={{ size: 3, offset: 1 }} className="meta">
            <AppSidebarComponent
              {...detailsToSidebar(this.state.store, this.state.executives)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(StoreDetailsContainer);
