import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const AppInfoModal = props => (
  <Modal isOpen className="modal-dialog-centered">
    <ModalBody>{props.children}</ModalBody>
  </Modal>
);

export default AppInfoModal;
