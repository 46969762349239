import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getClubs(startRange, endRange, params) {
    return http.get(`${resources.STORE}/${startRange};${endRange}`, {
      params
    });
  },

  getClubById(serviceId, params) {
    return http.get(`${resources.STORE}/${serviceId}`, { params });
  },

  getEventsByClubId(startRange, endRange, params) {
    return http.get(`${resources.EVENTS}/${startRange};${endRange}`, {
      params
    });
  }
};
