import { tileContentTypeToRoute } from './tileContentTypeToRoute';

export const isExternalLink = campus_link => {
  if (campus_link.link_type === 3) {
    // https://gitlab.com/ready-edu-doc/product-tech-doc/-/wikis/Data-Schemas/Primary-MySQL-DB/Campus-Link-Data-Table#details-of-link-types
    // If the link type is in-app link, then it's not external. We want to ignore open_in_browser just in case.
    // See https://oohlalaproduct.atlassian.net/browse/FES-7547
    return false;
  }
  return campus_link.open_in_browser;
};

export const tileLinkUrlToContentType = (linkUrl = null) => {
  return linkUrl ? linkUrl.replace('oohlala://', '') : null;
};

const linkParamsToQuery = linkParams => {
  let query = null;

  for (let key in linkParams) {
    if (Array.isArray(linkParams[key])) {
      query = linkParams[key].length
        ? `?${key}=${linkParams[key].join(',')}`
        : null;
    }
  }

  return query ? encodeURI(query) : null;
};

export const tileTypeToUrl = link => {
  const appLink = 3;
  const appRoute = tileContentTypeToRoute(
    tileLinkUrlToContentType(link.link_url),
    link.link_params.id
  );

  const queryParams = linkParamsToQuery(link.link_params);
  const webAppRoute = queryParams ? `${appRoute}${queryParams}` : `${appRoute}`;

  return link.link_type === appLink ? webAppRoute : link.link_url;
};
