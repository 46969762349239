import React from 'react';

import UserContent from '../../components/user-content/UserContent';

class ForgotPasswordExpired extends React.Component {
  render() {
    return (
      <UserContent
        main="user_reset_password"
        icon="clear"
        sub="user_reset_key_expired"
      />
    );
  }
}

export default ForgotPasswordExpired;
