import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { Route } from 'react-router-dom';

import routes from './campus-map-routes';
import { setSearch as resetDiningSearch } from './dining/store/actions';
import { setSearch as resetLocationSearch } from './locations/store/actions';

export class CampusMapContainer extends React.Component {
  componentDidMount() {
    document.body.classList.toggle('overflowHidden', this.props.isDark);
  }

  componentWillUnmount() {
    this.props.resetDiningSearch('');
    this.props.resetLocationSearch('');
    document.body.classList.remove('overflowHidden');
  }
  render() {
    return (
      <Container fluid>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        })}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetDiningSearch: payload => dispatch(resetDiningSearch(payload)),
    resetLocationSearch: payload => dispatch(resetLocationSearch(payload))
  };
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(CampusMapContainer);
