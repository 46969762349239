import CampusMapList from './locations/list/CampusMapList';
import CampusMapDining from './dining/list/CampusMapDining';

export default [
  {
    path: '/maps',
    exact: false,
    component: CampusMapList
  },
  {
    exact: false,
    path: '/dining',
    component: CampusMapDining
  }
];
