import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

import routes from './user-routes';
import { userPageInit } from './user-actions';
import service from '../../services/school.service';
import { production, staging } from '../../environment';
import UserNavbar from './components/user-navbar/UserNavbar';
import UserContent from './components/user-content/UserContent';
import AppSpinnerComponent from '../../components/app-spinner/AppSpinner';

class UserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      invalid: false
    };
  }

  getSchool() {
    const search = new URLSearchParams();
    search.append('webapp_url', window.location);

    return production || staging
      ? service.getSchoolByDomain(search)
      : service.getSchoolById(157);
  }

  componentDidMount() {
    this.getSchool()
      .then(school => {
        this.setState({ loading: false });
        this.props.dispatch(
          userPageInit({
            school
          })
        );
      })
      .catch(error => {
        this.setState({ loading: false, invalid: true });
      });
  }

  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : this.state.invalid ? (
      <div className="user">
        <UserNavbar />
        <UserContent
          main="user_invalid_token"
          icon="clear"
          sub="user_verification_failed_sub"
        />
      </div>
    ) : (
      <div className="user">
        <UserNavbar />
        {routes.map((route, index) => {
          return (
            <Route key={index} path={route.path} component={route.component} />
          );
        })}
      </div>
    );
  }
}

export default connect()(UserContainer);
