export const BREADCRUMB_UPDATE = 'BREADCRUMB_UPDATE';
export const BREADCRUMB_RESET = 'BREADCRUMB_RESET';

export const update = payload => ({
  type: BREADCRUMB_UPDATE,
  payload
});

export const reset = () => ({
  type: BREADCRUMB_RESET,
  payload: [
    {
      url: '/',
      label: 'browse'
    }
  ]
});
