import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { I18nextProvider } from 'react-i18next';

import './polyfills';

import App from './App';
import i18n from './i18n';
import { store } from './store';
import { production } from './environment';
import registerServiceWorker from './registerServiceWorker';

if (production) {
  ReactGA.initialize('UA-106501096-2');

  Sentry.init({
    dsn: 'https://feb99456e38e4c8e87f55d10eb90fae3@sentry.io/294686'
  });
}

const I18nApp = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(<I18nApp />, document.getElementById('root'));
registerServiceWorker();
