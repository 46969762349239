/**
 * List of supported Tile Link Urls
 * currently supported by the App
 */

export const supportedTiles = [
  'campus_link_list',

  'campus_service',

  'store',

  'job_list',

  'store_list',

  'school_campaign',

  'event_list',

  'campaign_list',

  'deal_store_list',

  'campus_service_list',

  'campus_poi_list',

  'campus_poi_list/dining',

  'campus_security_service'
];
