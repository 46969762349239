import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { setSearch } from '../store/actions';
import styles from './LocationCategoriesList.module.scss';
import { NoResults, CategoryResultCard } from '../../common/components';

class LocationCategoriesList extends React.Component {
  componentDidMount() {
    this.props.setSearch('');
  }

  categoryClick = catId => {
    this.props.history.push(`/maps/${catId}`);
  };

  render() {
    const { t, categories } = this.props;

    return (
      <ul className={`no_scroll_bar ${styles.categories__list}`}>
        <Row noGutters>
          {categories.length ? (
            categories.map(category => (
              <Col xs={4} key={category.id}>
                <li>
                  <CategoryResultCard
                    {...category}
                    categoryClick={this.categoryClick}
                  />
                </li>
              </Col>
            ))
          ) : (
            <NoResults message={t('t_shared_no_locations_found')} />
          )}
        </Row>
      </ul>
    );
  }
}

function mapStateToProps({ session, locations: { categories } }) {
  return {
    session,
    categories: categories.categories
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearch: payload => dispatch(setSearch(payload))
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LocationCategoriesList);
