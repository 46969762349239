import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getLinks(startRange, endRange, params) {
    return http.get(`${resources.LINKS}/${startRange};${endRange}`, {
      params
    });
  },

  getLinkById(eventId, params) {
    return http.get(`${resources.LINKS}/${eventId}`, { params });
  }
};
