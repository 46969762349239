import { combineReducers } from 'redux';

import session from './core/session/reducers';
import uiReducers from './core/ui/ui.reducers';
import user from '../containers/user/user-reducer';
import dining from '../containers/campus-map/dining/store/reducers';
import masterSearch from '../components/app-navbar/app-navbar-reducers';
import locations from '../containers/campus-map/locations/store/reducers';
import breadcrumb from '../components/app-breadcrumb/app-breadcrumb-reducers';

export default combineReducers({
  user,
  dining,
  session,
  locations,
  breadcrumb,
  masterSearch,
  ui: uiReducers
});
