export const objectTypeToRoute = obj_type => {
  switch (obj_type) {
    case 'campus_service':
      return 'services';

    case 'event':
      return 'events';

    case 'store':
      return 'stores';

    case 'campus_link':
      return 'links';

    default:
      return null;
  }
};
