import {
  MASTER_SEARCH_SEARCH,
  MASTER_SEARCH_RESET
} from './app-navbar-actions';

const defaultState = {
  searching: false,
  query: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MASTER_SEARCH_SEARCH:
      return { ...state, ...action.payload };
    case MASTER_SEARCH_RESET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
