import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.scss';
import service from '../jobs-service';
import { detailsToSidebar, yearsOfStudy, jobTypes } from '../jobs-utils';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppSidebarComponent from '../../../components/app-sidebar/AppSidebar';
import AppDownloadModal from '../../../components/app-download-modal/AppDownloadModal';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

class JobDetailsContainer extends React.Component {
  jobId;

  constructor(props) {
    super(props);
    this.jobId = this.props.match.params.jobId;
    this.state = {
      loading: true,
      service: null,
      downloadModal: false
    };

    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
  }

  toggleDownloadModal() {
    this.setState({ downloadModal: !this.state.downloadModal });
  }

  componentDidMount() {
    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service.getJobById(this.jobId, search).then(job => {
      this.setState(
        {
          loading: false,
          job,
          types: jobTypes(job)
        },
        () => {
          this.props.dispatch(
            updateBreadCrumb([
              {
                url: `jobs/${this.state.job.id}`,
                label: this.state.job.title
              }
            ])
          );
        }
      );
    });
  }
  render() {
    const { t } = this.props;
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="page-padding-top job page_job">
        <p className="h1 strong">{this.state.job.title}</p>
        <Row>
          <Col xs={12} lg={8}>
            <article>
              <main>
                <AppParagraph text={this.state.job.description} />
                <div className="divider" />
                <p className="h4">{t('jobs_how_to_apply')}</p>
                <AppParagraph text={this.state.job.how_to_apply} />
                <div className="divider" />
                {this.state.job.year_of_study.length > 0 && (
                  <p>
                    <Row>
                      <Col xs={6} lg={4}>
                        <span className="strong">
                          {t('jobs_desired_year_of_study')}
                        </span>
                      </Col>
                      <Col xs={6} lg={8}>
                        <span>
                          {yearsOfStudy(this.state.job.year_of_study)
                            .map(key => t(key))
                            .join(', ')}
                        </span>
                      </Col>
                    </Row>
                  </p>
                )}
                {this.state.types.length > 0 && (
                  <p>
                    <Row>
                      <Col xs={6} lg={4}>
                        <span className="strong">{t('jobs_job_type')}</span>
                      </Col>
                      <Col xs={6} lg={8}>
                        <span>
                          {this.state.types.map(key => t(key)).join(', ')}
                        </span>
                      </Col>
                    </Row>
                  </p>
                )}
              </main>
            </article>
          </Col>
          <Col xs={12} lg={{ size: 3, offset: 1 }} className="meta">
            <AppSidebarComponent {...detailsToSidebar(this.state.job)} />
          </Col>
        </Row>
        <AppDownloadModal
          t={this.props.t}
          isOpen={this.state.downloadModal}
          toggle={this.toggleDownloadModal}
        />
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(JobDetailsContainer);
