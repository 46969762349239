import { flatten } from 'lodash';
import {
  supportedTiles,
  isExternalLink,
  tileLinkUrlToContentType
} from '../../utils/tiles';

export const INVALID_RANK = -1;
export const CAMPUS_SECURITY_LINK = 'oohlala://campus_security_service';

export const validTile = tile => {
  const supportedLink =
    supportedTiles.includes(
      tileLinkUrlToContentType(tile.extra_info.link_url)
    ) || isExternalLink(tile.extra_info);

  const validLink =
    supportedLink && tile.extra_info.link_url !== CAMPUS_SECURITY_LINK;

  const validRank =
    tile.rank > INVALID_RANK || tile.featured_rank > INVALID_RANK;

  return validLink && validRank;
};

export const filterDefaultSectionTiles = appConfig => {
  let { personalized_guide } = appConfig;

  const guides = flatten(personalized_guide);

  personalized_guide = guides.filter(guide => guide.id !== 0);
  const defaultTiles = flatten(
    guides
      .filter(guide => guide.id === 0)
      .filter(guides => guides.tiles.length)
      .map(guides => guides.tiles)
  );

  return new Promise(resolve =>
    resolve({
      ...appConfig,
      defaultTiles,
      personalized_guide
    })
  );
};

export const filterOutSecurityTile = appConfig => {
  let { personalized_guide } = appConfig;

  const guides = flatten(personalized_guide);

  const securityType = tile =>
    tile.extra_info.link_url.split('/')[2] === 'campus_security_service';

  const tilesInfo = flatten(guides.map(guide => guide.tiles));

  const securityTile = tilesInfo.filter(tile => securityType(tile))[0];

  personalized_guide = guides.map(guide => {
    return {
      ...guide,
      tiles: guide.tiles.filter(tile => !securityType(tile))
    };
  });

  return new Promise(resolve =>
    resolve({
      ...appConfig,
      securityTile,
      personalized_guide
    })
  );
};

export const filterGuidesWithNoTiles = appConfig => {
  let { personalized_guide } = appConfig;

  const guides = flatten(personalized_guide);

  personalized_guide = guides.filter(guide => guide.tiles.length);

  return new Promise(resolve =>
    resolve({
      ...appConfig,
      personalized_guide
    })
  );
};

export const filterFeaturedTiles = appConfig => {
  let { personalized_guide } = appConfig;

  const featuredTiles = flatten(
    personalized_guide.map(guide => guide.tiles)
  ).filter(tile => tile.featured_rank > -1);

  personalized_guide = personalized_guide.map(guide => {
    return {
      ...guide,
      tiles: guide.tiles.filter(tile => tile.featured_rank === -1)
    };
  });

  return new Promise(resolve =>
    resolve({
      ...appConfig,
      featuredTiles
    })
  );
};

export const filterWebTilesOnly = appConfig => {
  let { personalized_guide } = appConfig;

  const guides = flatten(personalized_guide);

  const guidesWithTiles = guides.filter(guide => guide.tiles.length);

  personalized_guide = guidesWithTiles.map(guide => {
    return {
      ...guide,
      tiles: guide.tiles.filter(tile => validTile(tile))
    };
  });

  return new Promise(resolve =>
    resolve({
      ...appConfig,
      personalized_guide
    })
  );
};
