import React from 'react';
import { Col, Row } from 'reactstrap';

import styles from './MetaCard.module.css';

export const MetaCard = ({ description, src }) => {
  if (!description && !src) {
    return null;
  }

  return (
    <div className={styles.meta_card}>
      <Row>
        <Col xs={12}>
          {src && <img src={src} alt="location" />}
          {description && <p>{description}</p>}
        </Col>
      </Row>
    </div>
  );
};
