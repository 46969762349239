import { locationsActions } from '../actions';
import { locationToMarker } from '../../../common/utils';

const initialState = {
  error: false,
  loaded: false,
  locations: [],
  markers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case locationsActions.GET_LOCATIONS:
      return {
        ...state,
        error: false
      };

    case locationsActions.GET_LOCATIONS_FAIL:
      return {
        ...state,
        error: true
      };

    case locationsActions.GET_LOCATIONS_SUCCESS:
      const locations = action.payload;
      return {
        ...state,
        loaded: true,
        markers: locationToMarker(locations),
        locations: [...locations]
      };

    default:
      return state;
  }
};
