import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import styles from './CampusMapDining.module.scss';
import DiningList from '../dining-list/DiningList';
import { getLocationById } from '../store/selectors';
import { getDining, setSearch } from '../store/actions';
import { SideBarSearch } from '../../common/components';
import DiningDetails from '../dining-details/DiningDetails';
import AppMapComponent from '../../../../components/app-map/AppMap';
import SidebarLayout from '../../../../layouts/sidebar/SidebarLayout';
import AppSpinnerComponent from '../../../../components/app-spinner/AppSpinner';
import { update } from '../../../../components/app-breadcrumb/app-breadcrumb-actions';
class CampusMapDining extends React.Component {
  componentDidMount() {
    this.props.updateBreadCrumb([
      {
        url: 'dining',
        label: this.props.t('dining')
      }
    ]);

    if (this.props.shouldFetch) {
      this.fetch();
    }
  }

  fetch() {
    const params = new URLSearchParams();
    params.set('school_id', this.props.session.school.id);

    this.props.getDining(params);
  }

  onHandleSearch = query => {
    const pathname = '/dining';

    this.props.history.push({
      pathname
    });

    this.props.setSearch(query);
  };

  render() {
    const { query } = this.props;

    const {
      session: {
        school: { latitude, longitude }
      },
      markers,
      loading
    } = this.props;

    if (loading) {
      return <AppSpinnerComponent centered />;
    }

    return (
      <SidebarLayout
        content={
          <Map markers={markers} latitude={latitude} longitude={longitude} />
        }
        sidebar={<SideBar query={query} handleSearch={this.onHandleSearch} />}
      />
    );
  }
}

const SideBar = ({ query, handleSearch }) => {
  return (
    <React.Fragment>
      <Row className={styles.searchbox}>
        <Col xs={12}>
          <SideBarSearch value={query} onSearch={handleSearch} />
        </Col>
      </Row>
      <Route path="/dining" exact component={DiningList} />
      <Route path="/dining/:locationId" component={DiningDetails} />
    </React.Fragment>
  );
};

const Map = ({ latitude, longitude, markers }) => (
  <AppMapComponent
    zoom={15}
    markers={markers}
    center={{ lat: latitude, lng: longitude }}
  />
);

function mapStateToProps({ session, dining }) {
  const { markers, loaded } = dining.locations;

  return {
    markers,
    session,
    getLocationById,
    shouldFetch: !loaded,
    query: dining.ui.query,
    loading: dining.ui.loading,
    locations: dining.locations.locations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDining: params => dispatch(getDining(params)),
    setSearch: payload => dispatch(setSearch(payload)),
    updateBreadCrumb: payload => dispatch(update(payload))
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CampusMapDining);
