import AthleticDetailsContainer from './details/AthleticDetails';

export default [
  {
    exact: true,
    path: '/clubs',
    redirectTo: '/'
  },
  {
    exact: false,
    path: '/athletics/:clubId',
    component: AthleticDetailsContainer
  }
];
