import React from 'react';
import { NavbarBrand } from 'reactstrap';

const AppNavBrand = ({ logo }) => {
  return (
    <NavbarBrand>
      <img
        width="63"
        height="63"
        alt="School Avatar"
        className="rounded"
        src={logo}
      />
    </NavbarBrand>
  );
};

export default AppNavBrand;
