import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../events-service';
import { detailsToSidebar } from '../events-utils';
import AppButton from '../../../components/app-button/AppButton';
import AppAvatarComponent from '../../../components/app-avatar/AppAvatar';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppSidebarComponent from '../../../components/app-sidebar/AppSidebar';
import AppDownloadModal from '../../../components/app-download-modal/AppDownloadModal';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

class EventDetailsContainer extends React.Component {
  eventId;

  constructor(props) {
    super(props);
    this.eventId = this.props.match.params.eventId;
    this.state = {
      events: [],
      loading: true,
      service: null,
      downloadModal: false
    };

    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
  }

  toggleDownloadModal() {
    this.setState({ downloadModal: !this.state.downloadModal });
  }

  componentDidMount() {
    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service.getEventById(this.eventId, search).then(event => {
      this.setState(
        {
          loading: false,
          event
        },
        () => {
          this.props.dispatch(
            updateBreadCrumb([
              {
                url: `events/${this.state.event.id}`,
                label: this.state.event.title
              }
            ])
          );
        }
      );
    });
  }
  render() {
    const { t, accent } = this.props;

    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="page-padding-top event page_event">
        <p className="h1 strong">{this.state.event.title}</p>
        <Row>
          <Col xs={12}>
            <header>
              <AppAvatarComponent
                round
                size="small"
                className="page_event_host__avatar"
                src={this.state.event.store_logo_url}
              />
              <Link
                className="link page_event_host__name"
                to={`/stores/${this.state.event.store_id}`}
              >
                {this.state.event.store_name}
              </Link>
            </header>
          </Col>
          <Col xs={12} lg={8}>
            <article>
              <main>
                <div
                  className="page_event__hero__wrapper"
                  style={{
                    backgroundImage: 'url(' + this.state.event.poster_url + ')'
                  }}
                />
                <AppParagraph text={this.state.event.description} />
              </main>
            </article>
          </Col>
          <Col xs={12} lg={{ size: 3, offset: 1 }} className="meta">
            <div className="add_to_calendar">
              <AppButton
                block
                accent={accent}
                onClick={this.toggleDownloadModal}
              >
                {t('event_button_add_to_calendar')}
              </AppButton>
            </div>
            <AppSidebarComponent {...detailsToSidebar(this.state.event)} />
          </Col>
        </Row>
        <AppDownloadModal
          t={this.props.t}
          isOpen={this.state.downloadModal}
          toggle={this.toggleDownloadModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => {
  return {
    accent: session.school.branding_color
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(EventDetailsContainer);
