import i18n from 'i18next';
import * as moment from 'moment';
// If this is not explicity imported, then displaying dates in French will not work
import 'moment/locale/fr';
// Formats used are based on the docs here:
// https://momentjs.com/docs/#/displaying/format/

export const fromEpoch = (epochTimeStamp, showTime = true) => {
  moment.locale(i18n.language);
  const dateValid = epochTimeStamp > 0;
  return dateValid
    ? moment.unix(epochTimeStamp).format(showTime ? 'LLL' : 'LL')
    : null;
};

export function timeFromSeconds(seconds) {
  moment.locale(i18n.language);
  return moment()
    .startOf('day')
    .add(seconds, 'seconds')
    .format('LT');
}

export function getSecondsSinceMidnight() {
  moment.locale(i18n.language);
  const now = moment();
  const midNight = now.clone().startOf('day');

  return now.diff(midNight, 'seconds');
}
