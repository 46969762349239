import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { Translation, useTranslation } from 'react-i18next';

import styles from './LocationResultCard.module.scss';
import { timeFromSeconds } from '../../../../../utils/dates';
import {
  today,
  isRunning,
  dayMapping,
  addMissingDays,
  getScheduleForDay,
  startsInThirtyMinutes,
  filterItemsBasedOnCurrentTime
} from '../../utils';

const CardImage = ({ src, alt }) => {
  return <img className={styles.image} src={src} alt={alt} />;
};

const Badge = ({ schedule }) => {
  const { t } = useTranslation();
  const todaySchedule = getScheduleForDay(schedule);

  if (!schedule.length) {
    return null;
  }

  let isOpen = false;
  let isOpeningSoon = false;

  if (todaySchedule) {
    isOpen = todaySchedule.items.some(isRunning);
    isOpeningSoon = todaySchedule.items.some(startsInThirtyMinutes);
  }

  const label = isOpen
    ? 't_shared_open'
    : isOpeningSoon
    ? 't_shared_opening_soon'
    : 't_shared_closed';

  const openStyles = isOpen
    ? styles.open
    : isOpeningSoon
    ? styles.opening_soon
    : styles.closed;

  const classes = classNames(styles.badge, openStyles);

  return <span className={classes}>{t(label)}</span>;
};

const Time = ({ schedule }) => {
  if (!schedule.length) {
    return null;
  }

  schedule = addMissingDays(schedule);

  let day = null;
  let nextAvailableSchedule = getScheduleForDay(schedule);

  if (!nextAvailableSchedule.items.length) {
    nextAvailableSchedule = schedule
      // filter days with schedules
      .filter(t => t.items.length)
      // sort schedule by day asc from today
      .sort((_, b) => b.day > today)
      // get first item in array
      .shift();

    const tomorrow = nextAvailableSchedule.day === today + 1;
    const dayLabel = tomorrow
      ? 't_shared_tomorrow'
      : dayMapping[nextAvailableSchedule.day];

    day = <Translation>{t => t(dayLabel)}</Translation>;
  }

  const { start_time, end_time } = filterItemsBasedOnCurrentTime(
    nextAvailableSchedule.items
  );

  return (
    <p className={styles.time}>
      {day}
      <br />
      {timeFromSeconds(start_time)} - {timeFromSeconds(end_time)}
    </p>
  );
};

export const LocationResultCard = props => {
  const { t } = useTranslation();
  const {
    id,
    image_url,
    name,
    short_name,
    category_img_url,
    clickHandler,
    schedule,
    category_name
  } = props;

  return (
    <div className={styles.card} onClick={() => clickHandler(id)}>
      <Row noGutters>
        <Col xs={8} className={`${styles.image_wrapper} text-truncate`}>
          <div className={styles.image_bg}>
            <CardImage
              src={image_url ? image_url : category_img_url}
              alt={name}
            />
          </div>
          <div>
            <h3 title={name} className={`text-truncate ${styles.name}`}>
              {name}
            </h3>
            <p className={styles.short_name}>{short_name}</p>
            <p title={category_name} className={`${styles.type} text-truncate`}>
              {category_name}
            </p>
          </div>
        </Col>
        <Col xs={4} className={styles.meta}>
          <Badge schedule={schedule} t={t} />
          <Time schedule={schedule} />
        </Col>
      </Row>
    </div>
  );
};
