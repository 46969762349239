import { appSidebarTypes } from '../../components/app-sidebar/AppSidebar';

export const eventToListComponent = events => {
  return events.map(event => {
    return {
      id: event.id,
      heading: event.title,
      image: event.poster_thumb_url,
      description: event.description,
      linkTo: `/events/${event.id}`
    };
  });
};

export const detailsToSidebar = event => {
  return {
    hero: {
      src: event.logo_url,
      alt: event.name
    },
    map: {
      latitude: event.latitude,
      longitude: event.longitude
    },
    meta: [
      {
        id: 3,
        title: 'sidebar_location',
        type: appSidebarTypes.location,
        value: event.location
      }
    ]
  };
};
