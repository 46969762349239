import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getStores(startRange, endRange, params) {
    return http.get(`${resources.STORE}/${startRange};${endRange}`, {
      params
    });
  },

  getDeals(startRange, endRange, params) {
    return http.get(`${resources.DEALS}/${startRange};${endRange}`, {
      params
    });
  },

  getDealById(dealsId, params) {
    return http.get(`${resources.DEALS}/${dealsId}`, { params });
  },

  getStoreById(storeId, params) {
    return http.get(`${resources.STORE}/${storeId}`, { params });
  }
};
