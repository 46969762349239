import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.css';
import AppListItem from '../app-list-item/AppListItem';
import AppSpinnerComponent from '../app-spinner/AppSpinner';

const InfiteList = ({ items, loadMore }) => {
  return (
    <ul className="app_list">
      <InfiniteScroll
        next={loadMore}
        hasMore={true}
        height={600}
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
        loader={<AppSpinnerComponent centered />}
      >
        {items.map(item => {
          return (
            <li key={item.id} className="app_list__item">
              <AppListItem {...item} />
            </li>
          );
        })}
      </InfiniteScroll>
    </ul>
  );
};

const SimpleList = ({ items }) => {
  return (
    <ul className="app_list">
      {items.map(item => {
        return (
          <li key={item.id} className="app_list__item">
            <AppListItem {...item} />
          </li>
        );
      })}
    </ul>
  );
};

const AppList = ({ items, infinite, loadMore }) => {
  return infinite ? (
    <InfiteList items={items} loadMore={loadMore} />
  ) : (
    <SimpleList items={items} />
  );
};

export default AppList;
