import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './styles.css';
import { Col } from 'reactstrap';
import service from '../campaigns-service';
import Pagination from '../../../base/Pagination';
import { campaignsToListComponent } from '../campaigns-utils';
import AppList from '../../../components/app-list/AppList';
import AppSearchBarComponent from '../../../components/app-search/AppSearch';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppPagination from '../../../components/app-pagination/AppPagination';
import AppNoResultsComponent from '../../../components/app-no-results/AppNoResults';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';

class CampaignsListContainer extends React.Component {
  pagination = new Pagination();

  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      loading: false,
      search_str: null
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(search_str) {
    this.setState({ search_str }, () => this.fetch({ mutateState: true }));
  }

  goBack() {
    this.pagination.goBack();
    this.fetch({ mutateState: true });
  }

  goNext() {
    this.pagination.goNext();
    this.fetch({ mutateState: true });
  }

  componentDidMount() {
    this.props.dispatch(
      updateBreadCrumb([
        {
          url: 'campaigns',
          label: this.props.t('campaigns')
        }
      ])
    );
    this.fetch();
  }

  fetch(mutateState = false) {
    this.setState({ loading: true }, () => {
      const { startRange, endRange } = this.pagination;
      const search = new URLSearchParams();
      search.append('search_str', this.state.search_str);
      search.append('school_id', this.props.session.school.id);

      service.getCampaigns(startRange, endRange, search).then(campaigns => {
        this.setState({
          loading: false,
          campaigns: mutateState
            ? campaignsToListComponent(campaigns)
            : this.state.campaigns.concat(campaignsToListComponent(campaigns))
        });
      });
    });
  }

  render() {
    const { t } = this.props;

    const searchBar = (
      <AppSearchBarComponent
        placeholder={t('campaigns_search_placeholder')}
        onSearch={this.handleSearch}
      />
    );

    const campaignsList = (
      <div>
        <AppList items={this.state.campaigns} />
        <AppPagination
          goBack={this.goBack}
          goNext={this.goNext}
          page={this.pagination.currentPage}
          next={this.pagination.maxPerPage === this.state.campaigns.length}
        />
      </div>
    );
    return (
      <div className="services_page page-padding-top">
        <p className="h1 strong text-center">{t('campaigns')}</p>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          <div className="search">{searchBar}</div>
          {this.state.loading ? (
            <AppSpinnerComponent centered />
          ) : (
            <div>
              {this.state.campaigns.length ? (
                campaignsList
              ) : (
                <div className="no_results">
                  <AppNoResultsComponent query={this.state.search_str} />
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(CampaignsListContainer);
