import ServicesListContainer from './list/ServicesList';
import ServiceDetailsContainer from './details/ServiceDetails';

export default [
  {
    path: '/services',
    exact: true,
    component: ServicesListContainer
  },
  {
    exact: false,
    path: '/services/:serviceId',
    component: ServiceDetailsContainer
  }
];
