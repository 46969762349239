import logger from './logger.service';
import api from '../config/api/environment';
import { production, staging } from '../environment';
import { DEV_KEY } from '../config/api/keys';

const axios = require('axios');

const tokenByEnvironment = () => {
  return production || staging ? process.env.REACT_APP_API_KEY : DEV_KEY;
};

const instance = axios.create({
  baseURL: production || staging ? api[process.env.REACT_APP_REGION] : api.DEV,
  headers: { Authorization: `OToke ${tokenByEnvironment()}` }
});

const filterNullParams = params => {
  for (let key of params.keys()) {
    if (params.get(key) === 'null' || params.get(key) === '') {
      params.delete(key);
    }
  }
};

const http = {
  get(request, params = {}, silent = true) {
    if (Object.keys(params).length) {
      filterNullParams(params.params);
    }
    return new Promise((resolve, reject) => {
      instance
        .get(request, params)
        .then(res => resolve(res.data))
        .catch(err => (silent ? http.errorHandler(err) : reject(err)));
    });
  },
  put(request, body = {}) {
    return new Promise((resolve, reject) => {
      instance
        .put(request, body, { headers: { 'Content-Type': 'application/json' } })
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  },

  errorHandler(err) {
    logger.log(err);
  }
};

export default http;
