import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../deals-service';
import { detailsToSidebar } from '../deals-utils';
import AppAvatarComponent from '../../../components/app-avatar/AppAvatar';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppSidebarComponent from '../../../components/app-sidebar/AppSidebar';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import AppParagraph from '../../../components/app-paragraph/AppParagraph';

class DealDetailsContainer extends React.Component {
  dealId;

  constructor(props) {
    super(props);
    this.dealId = this.props.match.params.dealId;

    this.state = {
      events: [],
      store: null,
      loading: true,
      deal: null
    };
  }

  componentDidMount() {
    const search = new URLSearchParams();
    search.append('school_id', this.props.session.school.id);

    service
      .getDealById(this.dealId, search)
      .then(deal => {
        this.setState({ deal });
        return service.getStoreById(deal.store_id, search);
      })
      .then(store => {
        this.setState({ loading: false, store });
        this.props.dispatch(
          updateBreadCrumb([
            {
              url: `deals/${this.state.deal.id}`,
              label: this.state.deal.title
            }
          ])
        );
      });
  }
  render() {
    return this.state.loading ? (
      <AppSpinnerComponent centered />
    ) : (
      <div className="page-padding-top page_deal">
        <p className="h1 strong">{this.state.deal.title}</p>
        <Row>
          <Col xs={12}>
            <header>
              <AppAvatarComponent
                round
                size="small"
                className="page_deal_host__avatar"
                src={this.state.store.logo_url}
              />
              <Link
                className="link page_deal_host__name"
                to={`/deals/${this.state.deal.id}/store`}
              >
                {this.state.store.name}
              </Link>
            </header>
          </Col>
          <Col xs={12} lg={8}>
            <article>
              <main>
                <div className="page_deal__hero__wrapper">
                  <img
                    alt={this.state.deal.title}
                    src={this.state.deal.image_thumb_url}
                    className="img-fluid page_deal__hero"
                  />
                </div>
                <AppParagraph text={this.state.deal.description} />
              </main>
            </article>
          </Col>
          <Col xs={12} lg={{ size: 3, offset: 1 }} className="meta">
            <AppSidebarComponent
              {...detailsToSidebar(
                this.state.store,
                this.state.deal,
                this.props.t
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(DealDetailsContainer);
