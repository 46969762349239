import React from 'react';
import { useTranslation } from 'react-i18next';

import AppSearchComponent from '../../../../../components/app-search/AppSearch';

export const SideBarSearch = ({ onSearch, value }) => {
  const { t } = useTranslation();
  return (
    <AppSearchComponent
      value={value}
      onSearch={onSearch}
      placeholder={t('search')}
    />
  );
};
