import { call, put, takeEvery, select } from 'redux-saga/effects';
import { Translation } from 'react-i18next';
import React from 'react';

import service from '../../../campus-map.service';
import { toBase64 } from '../../../../../utils/svg';
import { campusMapLocationType } from '../../../campus-map-utils';
import { locationsActions, loadingStart, loadingEnd } from '../actions';
import { getSession } from '../../../../../store/core/session/selectors';
import { showSnackbar } from '../../../../../store/core/ui/snackbar/actions';
import {
  categoryToSvgData,
  createMarkerSvgMarkup
} from '../../../common/utils';

function* fetchLocations() {
  yield put(loadingStart());

  let locations = [];
  let categories = [];
  const { school } = yield select(getSession);

  const params = new URLSearchParams();
  params.set('school_id', school.id);

  try {
    categories = yield call(service.getLocationCategories, params);
    categories = categories.filter(
      c => c.category_type_id !== campusMapLocationType.dining
    );
    yield put({
      type: locationsActions.GET_CATEGORIES_SUCCESS,
      payload: categories
    });
  } catch (e) {
    yield put(loadingEnd());
    yield put(
      showSnackbar({
        type: 'danger',
        message: <Translation>{t => t('t_something_went_wrong')}</Translation>
      })
    );
    yield put({ type: locationsActions.GET_CATEGORIES_FAIL, payload: e });
  }

  try {
    locations = yield call(service.getLocations, 1, 1000, params);
    const locationCategories = categories.map(c => c.id);
    locations = locations.filter(c =>
      locationCategories.includes(c.category_id)
    );

    const categoryMarkerIcon = {};
    categories.forEach(c => {
      categoryMarkerIcon[c.id] = {
        icon: toBase64(
          createMarkerSvgMarkup(`#${c.color}`, categoryToSvgData[c.img_url])
        ),
        color: `#${c.color}`
      };
    });

    locations.forEach(l => {
      l['categoryMarkerIcon'] = categoryMarkerIcon[l.category_id].icon;
      l['categoryColor'] = categoryMarkerIcon[l.category_id].color;
    });

    yield put({
      type: locationsActions.GET_LOCATIONS_SUCCESS,
      payload: locations
    });
  } catch (e) {
    yield put(loadingEnd());
    yield put(
      showSnackbar({
        type: 'danger',
        message: <Translation>{t => t('t_something_went_wrong')}</Translation>
      })
    );
    yield put({ type: locationsActions.GET_LOCATIONS_FAIL, payload: e });
  }

  yield put(loadingEnd());
}

function* locationsSagas() {
  yield takeEvery(locationsActions.GET_LOCATIONS, fetchLocations);
}

export default locationsSagas;
