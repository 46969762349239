import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';
import { Row, Col } from 'reactstrap';
import { truncate } from '../../utils/text';

const LinkEl = props => {
  const { linkTo, external } = props;

  return external ? (
    <a
      href={linkTo}
      target="_blank"
      rel="noopener noreferrer"
      className="link link--text"
    >
      {props.children}
    </a>
  ) : (
    <Link to={linkTo} className="link link--text">
      {props.children}
    </Link>
  );
};

const AppListItem = ({
  id,
  heading,
  subheading,
  description,
  image,
  linkTo,
  external,
  clickHandler
}) => {
  return linkTo ? (
    <LinkEl className="item" linkTo={linkTo} external={external}>
      <Row>
        <Col xs={3} md={2}>
          <div
            className="item__avatar"
            style={{ backgroundImage: 'url(' + image + ')' }}
          />
        </Col>
        <Col xs={9} md={10}>
          <p className="strong">{heading}</p>
          <p className="muted">{subheading}</p>
          <p>{truncate(description, 110)}</p>
        </Col>
      </Row>
    </LinkEl>
  ) : (
    <Row>
      <Col xs={12} md={2}>
        <div
          className="item__avatar"
          style={{ backgroundImage: 'url(' + image + ')' }}
        />
      </Col>
      <Col xs={12} md={10}>
        <p className="h3">{heading}</p>
        <p className="muted">{subheading}</p>
        <p>{truncate(description, 110)}</p>
      </Col>
    </Row>
  );
};

export default AppListItem;
