import React from 'react';
import { Container, Col, Row } from 'reactstrap';

import '../../styles.css';
import AppListComponent from '../../../app-list/AppList';
import AppSpinnerComponent from '../../../app-spinner/AppSpinner';
import AppNoResultsComponent from '../../../app-no-results/AppNoResults';

const SearchResultsList = ({ suggestions, query }) => {
  return (
    <div className="search_results_container">
      {suggestions.length ? (
        <Row>
          <Col xs={12} md={{ size: 10, offset: 1 }}>
            <AppListComponent items={suggestions} />
          </Col>
        </Row>
      ) : (
        <AppNoResultsComponent query={query} />
      )}
    </div>
  );
};

const AppNavSearchResults = ({ query, searching, suggestions }) => {
  return (
    <Container>
      {searching ? (
        <AppSpinnerComponent centered />
      ) : (
        <SearchResultsList query={query} suggestions={suggestions} />
      )}
    </Container>
  );
};

export default AppNavSearchResults;
