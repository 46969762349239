import React, { useState, useEffect } from 'react';

import styles from './AppSearch.module.scss';
import { SearchIcon, CloseIcon } from '../../assets/icons/icons';

const AppSearchComponent = props => {
  const initialValue = props.value || '';

  const { placeholder, onSearch } = props;
  const [value, handleChange] = useState(initialValue);
  useEffect(() => handleChange(initialValue), [initialValue]);

  const onChange = value => {
    handleChange(value);
    if (!value) {
      onSearch('');
    }
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      onSearch(value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <span>
        <SearchIcon fill="#0076ff" />
        <input
          type="text"
          value={value}
          onKeyDown={event => onKeyDown(event)}
          className={styles.input}
          placeholder={placeholder}
          onChange={event => onChange(event.target.value)}
        />
      </span>
      {value.length > 0 && (
        <button
          type="button"
          className="button-reset"
          onClick={() => onChange('')}
        >
          <CloseIcon fill="#a2a2a2" />
        </button>
      )}
    </div>
  );
};

export default AppSearchComponent;
