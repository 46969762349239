import { appSidebarTypes } from '../../components/app-sidebar/AppSidebar';

export const serviceToListComponent = services => {
  return services.map(service => {
    return {
      ...service,
      heading: service.name,
      image: service.logo_url,
      linkTo: `/services/${service.id}`
    };
  });
};

export const categoriesToFilter = categories => {
  return categories.map(({ id, name }) => {
    return {
      id,
      label: name
    };
  });
};

export const paramsToAction = params => {
  const content = params.split('=');

  return {
    type: content[0] === '?category_ids' ? 'includes' : 'excludes',
    ids: content[1].split(',').map(id => Number(id))
  };
};

export const detailsToSidebar = service => {
  return {
    hero: {
      src: service.logo_url,
      alt: service.name
    },
    map: {
      latitude: service.latitude,
      longitude: service.longitude
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_email',
        type: appSidebarTypes.email,
        value: service.email
      },
      {
        id: 2,
        title: 'sidebar_phone',
        type: appSidebarTypes.phone,
        value: service.phone
      },
      {
        id: 3,
        title: 'sidebar_website',
        type: appSidebarTypes.url,
        value: service.website
      }
    ]
  };
};
