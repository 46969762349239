export default class Pagination {
  constructor(startRange = 1, endRange = 100) {
    this.currentPage = 1;
    this.startRange = startRange;
    this.endRange = endRange;
    this.maxPerPage = endRange;
  }

  goNext() {
    this.currentPage += 1;
    this.startRange = this.endRange + 1;
    this.endRange = this.endRange + this.maxPerPage;
  }

  goBack() {
    this.currentPage -= 1;
    this.endRange -= this.maxPerPage;
    this.startRange -= this.maxPerPage;
  }
}
