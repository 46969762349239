import VerifyContainer from './verify/Verify';
import ForgotPasswordContainer from './forgot-password/ForgotPassword';

export default [
  {
    path: '/user/verify/:key',
    component: VerifyContainer
  },
  {
    path: '/user/forgot_password',
    component: ForgotPasswordContainer
  }
];
