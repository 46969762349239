import React from 'react';

import UserContent from '../../components/user-content/UserContent';

class ForgotPasswordSuccess extends React.Component {
  render() {
    return (
      <UserContent
        main="user_reset_password"
        icon="done"
        sub="user_reset_success"
      />
    );
  }
}

export default ForgotPasswordSuccess;
