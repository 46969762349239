import React from 'react';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './styles.css';
import service from '../../navbar-service';
import { fromEpoch } from '../../../../utils/dates';
import AppNoResults from '../../../app-no-results/AppNoResults';
import AppSpinnerComponent from '../../../app-spinner/AppSpinner';
import AppNavAnnouncementModal from '../announcement-modal/AppNavAnnouncementModal';

class AppNavAnnouncementPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      loading: false,
      announcements: [],
      currentAnnouncement: null
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleAnnouncementClick = this.handleAnnouncementClick.bind(this);
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  handleAnnouncementClick(currentAnnouncement) {
    this.setState({ currentAnnouncement });
    this.toggleModal();
  }

  fetch() {
    this.setState({ loading: true }, () => {
      const search = new URLSearchParams();
      search.append('school_id', this.props.school.id);
      service.getAnnouncements(search).then(announcements => {
        this.setState({
          loading: false,
          announcements
        });
      });
    });
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    return (
      <div className="navbar_announcements app_navigation_popover">
        {this.state.loading ? (
          <AppSpinnerComponent centered />
        ) : (
          <div className="navbar_announcements_results">
            {this.state.announcements.length ? (
              this.state.announcements.map(item => {
                return (
                  <div
                    key={item.id}
                    className="navbar_announcements_result__item"
                    onClick={() => this.handleAnnouncementClick(item)}
                  >
                    <Row>
                      <Col xs={3}>
                        <img
                          alt={item.message}
                          src={item.store_logo_url}
                          className="img-fluid navbar_announcements_result__item__image"
                        />
                      </Col>
                      <Col
                        xs={9}
                        className="navbar_announcements_result__item__meta"
                      >
                        <p className="muted small">
                          {fromEpoch(item.sent_time)}
                        </p>
                        <p>{item.subject}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <AppNoResults />
            )}
            <AppNavAnnouncementModal
              isOpen={this.state.modal}
              toggle={this.toggleModal}
              announcement={this.state.currentAnnouncement}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(AppNavAnnouncementPopover);
