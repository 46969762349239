import { USER_SCHOOL_LOADED } from './user-actions';

const defaultState = {
  school: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_SCHOOL_LOADED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
