import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './styles.css';
import { Col } from 'reactstrap';
import service from '../links-service';
import Pagination from '../../../base/Pagination';
import { contains, parse } from '../../../utils/router';
import AppList from '../../../components/app-list/AppList';
import AppSpinnerComponent from '../../../components/app-spinner/AppSpinner';
import AppNoResultsComponent from '../../../components/app-no-results/AppNoResults';
import { update as updateBreadCrumb } from '../../../components/app-breadcrumb/app-breadcrumb-actions';
import {
  linksToList,
  filterOutSecurityTile,
  webFeaturesOnly
} from '../links-utils';
import AppPagination from '../../../components/app-pagination/AppPagination';

class LinksListContainer extends React.Component {
  pagination = new Pagination();

  constructor(props) {
    super(props);

    this.state = {
      links: [],
      loading: false,
      search_str: null
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.searchParams = parse(this.props.location.search);
  }

  goBack() {
    this.pagination.goBack();
    this.fetch();
  }

  goNext() {
    this.pagination.goNext();
    this.fetch();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.searchParams = parse(nextProps.location.search);
      this.fetch();
    }
  }

  componentDidMount() {
    this.props.dispatch(
      updateBreadCrumb([
        {
          url: `links${this.props.location.search}`,
          label: this.props.t('links')
        }
      ])
    );
    this.fetch();
  }

  fetch(mutateState = false) {
    const ids = contains(this.searchParams, 'ids')
      ? this.searchParams.get('ids')
      : null;

    this.setState({ loading: true }, () => {
      const { startRange, endRange } = this.pagination;
      const search = new URLSearchParams();

      if (ids) {
        search.append('campus_link_ids', ids);
      }
      search.append('search_str', this.state.search_str);
      search.append('school_id', this.props.session.school.id);

      service
        .getLinks(startRange, endRange, search)
        .then(links => Promise.resolve(filterOutSecurityTile(links)))
        .then(links => Promise.resolve(webFeaturesOnly(links)))
        .then(links => {
          this.setState({
            loading: false,
            links: linksToList(links)
          });
        });
    });
  }

  render() {
    const { t } = this.props;

    const linksList = <AppList items={this.state.links} />;
    return (
      <div className="services_page page-padding-top">
        <p className="h1 strong text-center">{t('links')}</p>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          {this.state.loading ? (
            <AppSpinnerComponent centered />
          ) : (
            <div>
              {this.state.links.length ? (
                <div>
                  {linksList}
                  <AppPagination
                    goBack={this.goBack}
                    goNext={this.goNext}
                    page={this.pagination.currentPage}
                    next={
                      this.pagination.maxPerPage === this.state.links.length
                    }
                  />
                </div>
              ) : (
                <div className="no_results">
                  <AppNoResultsComponent query={this.state.search_str} />
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect()
)(LinksListContainer);
