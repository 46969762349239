import ForgotPasswordForm from './form/ForgotPasswordForm';
import ForgotPasswordSuccess from './success/ForgotPasswordSuccess';
import ForgotPasswordExpired from './expired/ForgotPasswordExpired';

export default [
  {
    path: '/user/forgot_password/success',
    component: ForgotPasswordSuccess
  },
  {
    path: '/user/forgot_password/expired',
    component: ForgotPasswordExpired
  },
  {
    path: '/user/forgot_password/:key',
    component: ForgotPasswordForm
  }
];
