import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getEvents(startRange, endRange, params) {
    return http.get(`${resources.EVENTS}/${startRange};${endRange}`, {
      params
    });
  },

  getEventById(eventId, params) {
    return http.get(`${resources.EVENTS}/${eventId}`, { params });
  }
};
