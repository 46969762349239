import { Navbar, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import React from 'react';

import './styles.scss';

class UserNavbar extends React.Component {
  render() {
    return (
      <Navbar className="user__navbar">
        <NavItem className="user__navbar__image">
          <img alt="" src={this.props.icon} />
        </NavItem>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    icon: user.school.app_logo_url
  };
};

export default connect(mapStateToProps)(UserNavbar);
