import React from 'react';

import noResults from './no-results.svg';
import styles from './NoResults.module.scss';

export const NoResults = ({ message }) => (
  <div className={styles.wrapper}>
    <div className={styles.img_wrapper}>
      <img src={noResults} alt="no results" />
    </div>

    <p className={styles.text}>{message}</p>
  </div>
);
