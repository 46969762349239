export default {
  name: 'i18nDetector',

  lookup(options) {
    // options -> are passed in options
    const defaultLocale = 'en-US';
    const browserLanguage =
      navigator.userLanguage || navigator.language || defaultLocale;

    return browserLanguage.toLowerCase().startsWith('fr') ? 'fr' : 'en';
  },

  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  }
};
