import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import './styles.scss';
import megaphone from './assets/icon_announcement.png';

const AppNavAnnouncementModal = ({ isOpen, toggle, announcement, accent }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      {announcement ? (
        <div className="announcement">
          <div
            className="announcement__header"
            style={{ backgroundColor: accent }}
          >
            <button onClick={toggle} className="announcement__close">
              <i className="material-icons">close</i>
            </button>
            <img src={megaphone} alt="announcement" />
          </div>
          <ModalBody className="text-center">
            <p className="h2">{announcement.subject}</p>
            <p>{announcement.message}</p>
          </ModalBody>
        </div>
      ) : null}
    </Modal>
  );
};

const mapStateToProps = ({ session }) => {
  return {
    accent: session.school.branding_color
  };
};

export default connect(mapStateToProps)(AppNavAnnouncementModal);
