import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Modal, ModalBody } from 'reactstrap';

import './styles.scss';
import AppButton from '../../../components/app-button/AppButton';

const ExperienceModal = props => {
  const { personas, isOpen, onToggleModal, buttonClick, t, accent } = props;

  return (
    <Modal
      keyboard
      isOpen={isOpen}
      toggle={onToggleModal}
      className="modal-dialog-centered modal-lg"
    >
      <ModalBody className="personas__modal__body">
        <Row>
          <Col
            xs={12}
            md={5}
            className="personas__modal__meta__wrapper"
            style={{ backgroundColor: accent }}
          >
            <div className="personas__modal__meta">
              <p className="personas__modal__title">
                {t('personas_modal_title')}
              </p>
              <p className="personas__modal__copy">
                {t('personas_modal_description')}
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            md={{ size: 4, offset: 1 }}
            className="personas__modal__buttons"
          >
            {personas.map(persona => {
              return (
                <AppButton
                  block
                  accent={accent}
                  key={persona.id}
                  onClick={() => buttonClick(persona)}
                >
                  {persona.name}
                </AppButton>
              );
            })}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(ExperienceModal);
