import { appSidebarTypes } from '../../components/app-sidebar/AppSidebar';
import { fromEpoch } from '../../utils/dates';

export const storeToListComponent = stores => {
  return stores.map(store => {
    return {
      id: store.id,
      heading: store.franchise_name,
      image: store.logo_url,
      description: store.description,
      linkTo: `/deals/${store.id}`
    };
  });
};

export const dealToListComponent = deals => {
  return deals.map(deal => {
    return {
      id: deal.id,
      heading: deal.title,
      image: deal.image_url,
      description: deal.description,
      linkTo: `/deals/${deal.id}`
    };
  });
};

export const noDateDeal = deal => deal.expiration === -1 && deal.start === 0;

export const startDateOnlyDeal = deal =>
  deal.expiration === -1 && deal.start > 0;

export const endDateOnlyDeal = deal => deal.expiration > 0 && deal.start === 0;

export const startAndEndDateDeal = deal =>
  deal.expiration > 0 && deal.start > 0;

export const formatDealDate = (deal, t) => {
  if (noDateDeal(deal)) {
    return t('ongoing');
  } else if (startDateOnlyDeal(deal)) {
    return `${t('from')} ${fromEpoch(deal.start)}`;
  } else if (endDateOnlyDeal(deal)) {
    return `${t('until')} ${fromEpoch(deal.expiration)}`;
  } else if (startAndEndDateDeal(deal)) {
    return `${fromEpoch(deal.start)} - ${fromEpoch(deal.expiration)}`;
  }
};

export const storeDetailsToSidebar = store => {
  return {
    hero: {
      src: store.logo_url,
      alt: store.name
    },
    map: {
      latitude: store.latitude,
      longitude: store.longitude
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_email',
        type: appSidebarTypes.email,
        value: store.email
      },
      {
        id: 2,
        title: 'sidebar_phone',
        type: appSidebarTypes.phone,
        value: store.phone
      },
      {
        id: 3,
        title: 'sidebar_website',
        type: appSidebarTypes.url,
        value: store.website
      },
      {
        id: 4,
        title: 'sidebar_location',
        type: appSidebarTypes.location,
        value: store.address
      }
    ]
  };
};

export const detailsToSidebar = (store, deal, t) => {
  return {
    hero: {
      src: null,
      alt: null
    },
    map: {
      latitude: store.latitude,
      longitude: store.longitude
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_email',
        type: appSidebarTypes.email,
        value: store.email
      },
      {
        id: 2,
        title: 'sidebar_phone',
        type: appSidebarTypes.phone,
        value: store.phone
      },
      {
        id: 3,
        title: 'sidebar_website',
        type: appSidebarTypes.url,
        value: store.website
      },
      {
        id: 4,
        title: 'sidebar_location',
        type: appSidebarTypes.location,
        value: store.address
      },
      {
        id: 5,
        title: 'sidebar_date',
        type: appSidebarTypes.phone,
        value: deal ? formatDealDate(deal, t) : null
      }
    ]
  };
};
