import { BREADCRUMB_UPDATE, BREADCRUMB_RESET } from './app-breadcrumb-actions';

const defaultState = [
  {
    url: '/',
    label: 'browse'
  }
];

export default (state = defaultState, action) => {
  switch (action.type) {
    case BREADCRUMB_UPDATE:
      return [...action.payload];
    case BREADCRUMB_RESET:
      return [...action.payload];
    default:
      return state;
  }
};
