import React from 'react';
import { Col, Row } from 'reactstrap';

import styles from './SidebarLayout.module.scss';

const SidebarLayout = ({ sidebar, content }) => {
  return (
    <Row className={styles.container}>
      <Col
        xs={{ size: 12 }}
        xl={3}
        className={`no_scroll_bar ${styles.sidebar}`}
      >
        {sidebar}
      </Col>
      <Col xs={{ size: 12 }} xl={9} className={styles.fixed_container}>
        {content}
      </Col>
    </Row>
  );
};

export default SidebarLayout;
