export const campaignsToListComponent = campaigns => {
  return campaigns.map(campaign => {
    return {
      id: campaign.id,
      heading: campaign.name,
      image: campaign.campaign_image_url,
      description: campaign.description,
      linkTo: `/campaigns/${campaign.id}`
    };
  });
};

const parseYouTube = videoUrl => {
  const host = 'https://www.youtube.com';
  const videoId = videoUrl.split('?v=')[1];

  return `${host}/embed/${videoId}`;
};

const parseVimeo = videoUrl => {
  const host = 'https://www.vimeo.com';
  const videoId = videoUrl.split('/')[2];

  return `${host}/video/${videoId}`;
};

const videoUrlToWebEmbedable = videoUrl => {
  return videoUrl.includes('youtube')
    ? parseYouTube(videoUrl)
    : parseVimeo(videoUrl);
};

export const carouselData = (images, videos) => {
  images = images.length
    ? images.map(image => {
        const [src, alt] = image;
        return {
          src: src,
          alt,
          type: 'image'
        };
      })
    : [];

  videos = videos.length
    ? videos.map(video => {
        return {
          src: videoUrlToWebEmbedable(video),
          alt: 'Video alt',
          type: 'video'
        };
      })
    : [];

  return [...videos, ...images];
};
