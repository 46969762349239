import http from '../../services/http.service';
import resources from '../../config/api/resources';

export default {
  getServiceCategories(params, filterType = null, filterBy = null) {
    return http
      .get(`${resources.SERVICES}/`, {
        params
      })
      .then(response => {
        if (filterType) {
          response =
            filterType === 'includes'
              ? response.filter(category => filterBy.includes(category.id))
              : response.filter(category => !filterBy.includes(category.id));

          return Promise.resolve(response);
        }
        return Promise.resolve(response);
      });
  },

  getServices(startRange, endRange, params) {
    return http.get(`${resources.SERVICES}/${startRange};${endRange}`, {
      params
    });
  },

  getServiceById(serviceId, params) {
    return http.get(`${resources.SERVICES}/${serviceId}`, { params }, false);
  },

  getEventsByServiceId(startRange, endRange, params) {
    return http.get(`${resources.EVENTS}/${startRange};${endRange}`, {
      params
    });
  }
};
