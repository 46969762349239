import { diningActions } from '../actions';

const initialState = {
  error: false,
  categories: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case diningActions.GET_CATEGORIES:
      return {
        ...state,
        error: false
      };

    case diningActions.GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: true
      };

    case diningActions.GET_CATEGORIES_SUCCESS:
      const categories = action.payload;

      return {
        ...state,
        categories: [...categories]
      };

    default:
      return state;
  }
};
