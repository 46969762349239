import { appSidebarTypes } from '../../components/app-sidebar/AppSidebar';

export const clubMemberType = {
  normal: 0,
  creator: 1,
  executive: 2
};

export const detailsToSidebar = (service, executives) => {
  return {
    hero: {
      src: service.logo_url,
      alt: service.name
    },
    map: {
      latitude: service.latitude,
      longitude: service.longitude
    },
    list: {
      heading: 'clubs_sidebar_executives_header',
      data: executives.map(exec => {
        return {
          id: exec.id,
          avatar: exec.avatar_thumb_url,
          label: `${exec.firstname} ${exec.lastname}`,
          subheading: exec.member_position
        };
      })
    },
    meta: [
      {
        id: 1,
        title: 'sidebar_email',
        type: appSidebarTypes.email,
        value: service.email
      },
      {
        id: 2,
        title: 'sidebar_phone',
        type: appSidebarTypes.phone,
        value: service.phone
      },
      {
        id: 3,
        title: 'sidebar_website',
        type: appSidebarTypes.url,
        value: service.website
      }
    ]
  };
};
