import React from 'react';

import styles from './AppParagraph.module.scss';

/** Possible strings identifying the beginning of a link */
const HTTP_LINK_PREFIXES = ['http://', 'https://', 'tel://', 'mailto://'];

/** Possible strings identifying the end of a link */
const HTTP_LINK_SUFFIXES = [' ', '\t', '\n'];

/**
 * Scans inputString for occurrences of searchStrings starting at searchPosition and returns the index of the closest occurrence
 *
 * If there are no occurences at all, returns the last index of inputString aka inputString.length
 * */
const getClosestOrEndIndex = (inputString, searchStrings, searchPosition) => {
  let foundIndex = inputString.length;
  for (let searchString of searchStrings) {
    let i = inputString.indexOf(searchString, searchPosition);
    if (i !== -1 && i < foundIndex) {
      foundIndex = i;
    }
  }
  return foundIndex;
};

/**
 * Converts a text string into a linkified array of components:
 * - Regular text will be converted to "<span>base text</span>"
 * - Link text will be converted to "<a ...>link text</a>"
 *
 * For example, calling this function with the following text:
 * """"
 * This is some text with a https://www.readyeducation.com link in it!
 * """"
 *
 * Will return the following array of components:
 * [
 *   <span key="span0">This is some text with a </span>
 *   <a key="a0" target="_blank" href="https://www.readyeducation.com">https://www.readyeducation.com</a>
 *   <span key="span1"> link in it!</span>
 * ]
 * */
const linkifyText = inputText => {
  if (inputText == null || typeof inputText !== 'string') {
    return '';
  }
  let linkStartIndexes = [];
  for (let linkPrefix of HTTP_LINK_PREFIXES) {
    let currentIndex = inputText.indexOf(linkPrefix, 0);
    while (currentIndex !== -1) {
      linkStartIndexes.push(currentIndex);
      currentIndex = inputText.indexOf(linkPrefix, currentIndex + 1);
    }
  }
  if (linkStartIndexes.length === 0) {
    return [inputText];
  }
  linkStartIndexes.sort((a, b) => {
    return a - b;
  });
  let linkifiedTextComponents = [];
  let currentIndex = 0;
  for (let i = 0; i < linkStartIndexes.length; i++) {
    let linkStartIndex = linkStartIndexes[i];
    // If there is some text before the link, adding it to the linkified text data
    if (currentIndex < linkStartIndex) {
      linkifiedTextComponents.push(
        <span key={'span' + i}>
          {inputText.substring(currentIndex, linkStartIndex)}
        </span>
      );
    }
    let linkEndIndex = getClosestOrEndIndex(
      inputText,
      HTTP_LINK_SUFFIXES,
      linkStartIndex
    );
    let linkText = inputText.substring(linkStartIndex, linkEndIndex);
    linkifiedTextComponents.push(
      <a key={'a' + i} target="_blank" href={linkText}>
        {linkText}
      </a>
    );
    currentIndex = linkEndIndex;
  }
  if (currentIndex < inputText.length) {
    linkifiedTextComponents.push(
      <span key={'span' + linkStartIndexes.length}>
        {inputText.substring(currentIndex, inputText.length)}
      </span>
    );
  }
  return linkifiedTextComponents;
};

class AppParagraph extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <p className={styles.app_paragraph}>{linkifyText(this.props.text)}</p>
    );
  }
}

export default AppParagraph;
