import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { setSearch } from '../store/actions';
import styles from './DiningList.module.scss';
import { findLocationsBy } from '../../common/utils';
import { NoResults, LocationResultCard } from '../../common/components';

class DiningList extends React.Component {
  locationClick = locationId => {
    this.props.history.push(`/dining/${locationId}`);
  };

  render() {
    const { t, query, locations } = this.props;

    const listItems = query ? findLocationsBy(locations, query) : locations;

    return (
      <React.Fragment>
        <ul className={`no_scroll_bar ${styles.locations__list}`}>
          <Row noGutters>
            {listItems.length ? (
              listItems.map(location => (
                <Col xs={12} key={location.id}>
                  <li>
                    <LocationResultCard
                      {...location}
                      clickHandler={this.locationClick}
                    />
                  </li>
                </Col>
              ))
            ) : (
              <NoResults message={t('t_shared_no_locations_found')} />
            )}
          </Row>
        </ul>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ dining, session }) {
  return {
    session,
    query: dining.ui.query,
    locations: dining.locations.locations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearch: payload => dispatch(setSearch(payload))
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DiningList);
