import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import './styles.scss';

const pathToCrumbs = crumbs => {
  return crumbs
    .filter(crumb => crumb.url !== '/')
    .map((path, index) => {
      return {
        id: index,
        label: path.label,
        url: `/${path.url}`
      };
    });
};

const AppBreadcrumbComponent = props => {
  const isHomePage = props.location.pathname === '/';
  const pathAsArray = isHomePage ? [] : pathToCrumbs(props.crumbs);

  return pathAsArray.length ? (
    <div className="app_breadcrumb__border d-none d-sm-none d-md-block">
      <Container>
        <Breadcrumb className="app_breadcrumb">
          <BreadcrumbItem className="app_breadcrumb_item">
            <Link className="app_breadcrumb_link link" to="/">
              {props.t('browse')}
            </Link>
          </BreadcrumbItem>
          {pathAsArray.map(path => {
            return (
              <BreadcrumbItem className="app_breadcrumb_item" key={path.id}>
                <Link className="app_breadcrumb_link link" to={path.url}>
                  {path.label}
                </Link>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
      </Container>
    </div>
  ) : null;
};

const mapStateToProps = ({ breadcrumb }) => {
  return {
    crumbs: breadcrumb
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(AppBreadcrumbComponent);
