import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';

import diningsSagas from '../containers/campus-map/dining/store/sagas';
import locationsSagas from '../containers/campus-map/locations/store/sagas';

export const sagaMiddleware = createSagaMiddleware();

export default function* root() {
  yield fork(locationsSagas);
  yield fork(diningsSagas);
}
