export const truncate = (text, maxTotalLength) => {
  if (
    text == null ||
    typeof text !== 'string' ||
    typeof maxTotalLength !== 'number'
  ) {
    // If the inputs are insane, returning '...'
    return '...';
  }
  if (maxTotalLength <= 3) {
    // The max total length cannot be lower than 3 because the
    // ellipsized string will still have '...'
    return text.length <= 3 ? text : '...';
  }

  if (text.length > maxTotalLength) {
    return `${text.substring(0, maxTotalLength - 3)}...`;
  } else {
    return text;
  }
};
