import React from 'react';
import { Link } from 'react-router-dom';

import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import './styles.scss';

const LinkEl = props => {
  return props.external ? (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className="link link--text"
    >
      {props.children}
    </a>
  ) : (
    <Link to={props.to} className="link link--text">
      {props.children}
    </Link>
  );
};

class AppCarouselComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = this.props.items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          {item.type === 'image' ? (
            <div
              className="carousel_img_wrapper"
              style={{ backgroundImage: 'url(' + item.src + ')' }}
            />
          ) : (
            <iframe
              src={item.src}
              width="100%"
              title="video"
              height="320px"
              frameBorder="0"
            />
          )}
          {item.caption ? (
            <LinkEl to={item.linkTo} external={item.external}>
              <CarouselCaption captionText={item.caption} />
            </LinkEl>
          ) : null}
        </CarouselItem>
      );
    });

    return (
      <Carousel
        keyboard={false}
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators
          items={this.props.items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
          className="app_carousel_indicator"
        />
        {slides}
      </Carousel>
    );
  }
}

export default AppCarouselComponent;
